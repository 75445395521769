import * as React from 'react';
import {BoardId} from "../../../model/Board";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {FormField, Message} from "semantic-ui-react";
import ExpandButton from "./ExpandButton";

interface OwnProps {
  boardId: BoardId;
  streamId: string;
  onStreamIdChanged: (streamId: string) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
  ctx: WizardContext;
}

type StreamIdEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const StreamIdEditor = (props: StreamIdEditorProps) => {
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }


    let value = JSON.stringify({"streamId": props.streamId}, null, 2);
    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `stream-id.json`,
      language: "json",
      value,
      schema: JSON.stringify({
        "type": "object",
        "additionalProperties": false,
        "required": ["streamId"],
        "properties": {
          "streamId": {
            "type": "string",
            "markdownDescription": "No two commands can record events with the same streamId at the same time.\n\n**Optimistic Locking** is used to block concurrent writes.\n\n**Jexl Expression**\n\nScope:\n\n- **command**\n- **meta**"
          }
        }
      }),
    });

    setInvalidJson(isInvalidJson);
  }, [props.streamId]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onStreamIdChanged('');

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedConfig = JSON.parse(editorStr);
      props.onStreamIdChanged(changedConfig.streamId || '');

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] UI Config Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorExpanded} onExpandChanged={expand => setEditorExpanded(expand)}/>
    <CodeEditor
      containerId={'cody-stream-id-editor'}
      ref={codeEditorRef}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorExpanded ? ' expanded' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(StreamIdEditor);
