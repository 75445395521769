import {List, Record} from "immutable";
import shortUUID from "short-uuid";
import {DeepLinkFactory} from "../../routes";
import {CommandMetadata, getCommandMetadata} from "../service/cody-wizard/command/get-command-metadata";
import {
    EventMetadata,
    getEventMetadata
} from "../service/cody-wizard/event/get-event-metadata";
import {names} from "../service/cody-wizard/node/names";
import {Schema} from "../service/cody-wizard/schema/schema";
import {getSchemaRefName} from "../service/cody-wizard/vo/get-schema-ref-name";
import {getVoMetadata, ValueObjectMetadata} from "../service/cody-wizard/vo/get-vo-metadata";
import {createTreeFromGraph, ElementsTree} from "./ElementsTree";
import {HistoryCellType} from "./HistoryEntry";
import {parseNodeMetadata} from "../service/cody-wizard/node/metadata";

export type NodeId = string;
export type NodeName = string;
export type NodeDescription = string;
export type NodeTag = string;
export type NodeLink = string;
export type NodeMetadata = string;
export type NodeLabel = string; // Name + Description
export interface NodeSize {width: number; height: number}
export enum NodeType {
    event = 'event',
    command = 'command',
    role = 'role',
    projection = 'projection',
    aggregate = 'aggregate',
    document = 'document',
    policy = 'policy',
    hotSpot = 'hotSpot',
    externalSystem = 'externalSystem',
    ui = 'ui',
    feature = 'feature',
    slice = 'slice',
    swimlanes = 'siwmlanes',
    boundedContext = 'boundedContext',
    freeText = 'freeText',
    textCard = 'textCard',
    edge = 'edge',
    misc = 'misc',
    icon = 'icon',
    image = 'image',
    layer = 'layer'
}

export interface GraphPoint {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export enum NextToPosition {
    left = 'left',
    right = 'right',
    above = 'above',
    below = 'below',
    aboveLeft = 'aboveLeft',
    aboveRight = 'aboveRight',
    belowLeft = 'belowLeft',
    belowRight = 'belowRight',
}

export type PanningListener = (translate: GraphPoint) => void;
export type ZoomListener = (scale: number, translate: GraphPoint) => void;
export type MoveVideoToPositionListener = (position: {x: number, y: number}) => void;
export type CodyCallback = () => void;

export interface Node {
    getId: () => NodeId;
    getName: () => NodeName;
    getTechnicalName: () => NodeName;
    changeName: (name: NodeName) => Node;
    getDescription: () => NodeDescription;
    getType: () => NodeType;
    getLink: () => NodeLink | null;
    getTags: () => List<NodeTag>;
    isLayer: () => boolean;
    isDefaultLayer: () => boolean;
    getParent: () => Node | null;
    changeParent: (parent: Node | null) => Node;
    children: () => List<Node>;
    setChildren: (children: List<Node>) => Node;
    getGeometry: () => GraphPoint;
    changeGeometry: (geometry: GraphPoint) => Node;
    getSources: () => List<Node>;
    getTargets: () => List<Node>;
    getMetadata: () => string | null;
    changeMetadata: (metadata: string) => Node;
    isEnabled: () => boolean;
    getSize: () => Size;
    changeSize: (size: Size) => Node;
}

export interface Graph {
    createCellId: () => string;
    getCurrentScale: () => number;
    getCurrentTranslate: () => GraphPoint;
    onPanning: (listener: PanningListener) => void;
    offPanning: (listener: PanningListener) => void;
    translateMousePoint: (x: number, y: number) => GraphPoint;
    onZoom: (listener: ZoomListener) => void;
    offZoom: (listener: ZoomListener) => void;
    getRoot: () => Node;
    openLayersDialog: () => void;
    triggerCody: () => void;
    selectNode: (node: Node) => boolean;
    hasUserLevelVisibilityChanges: () => boolean;
    isVisible: (node: Node) => boolean;
    setUserVisible: (node: Node, visible: boolean) => void;
    applyVisibilityGlobally: () => void;
    isLocked: (node: Node) => boolean;
    setLocked: (node: Node, locked: boolean) => void;
    deleteNode: (node: Node) => void;
    addLayer: (name: string) => void;
    deleteLayer: (node: Node) => void;
    isActiveLayer: (node: Node) => boolean;
    setAsActiveLayer: (node: Node) => void;
    setDefaultLayerAsActive: () => void;
    highlightLayer: (node: Node) => void;
    addNode: (node: Node) => void;
    getNode: (nodeId: string) => Node | null;
    addNodeNextToAnother: (newNode: Node, existingNode: Node, position: NextToPosition, margin: number| {x: number, y: number}, center?: boolean) => void;
    moveNodeNextToAnother: (node: Node, existingNode: Node, position: NextToPosition, margin: number | {x: number, y: number}, center?: boolean) => void;
    changeNodeName: (node: Node, newName: string) => void;
    changeNodeDescription: (node: Node, description: NodeDescription) => void;
    changeNodeLabel: (node: Node, label: NodeLabel) => void;
    changeNodeMetadata: (node: Node, metadata: NodeMetadata) => void;
    changeNodeTags: (node: Node, tags: List<NodeTag>) => void;
    resizeNode: (node: Node, size: Size) => void;
    moveNode: (node: Node, toPosition: GraphPoint) => void;
    switchParent: (node: Node, parent: Node | null) => void;
    connectNodes: (source: Node, target: Node, connectionLabel?: string, edgePoints?: GraphPoint[], invertCurve?: boolean) => void;
    highlightNodes: (nodes: Node[]) => void;
    copySelectionToActiveLayer: () => boolean;
    changeFeatureStatus: (feature: Node, status: 'important' | 'planned' | 'ready' | 'deployed') => void;
    getFeatureTaskLink: (feature: Node) => string | null;
    setFeatureTaskLink: (feature: Node, link: string | null) => void;
    exportXmlSnapshot: () => string;
    onSelectionChanged: (listener: (hasSelection: boolean) => void) => void;
    setCockpitBaseUrl: (baseUrl: string) => void;
    focus: () => void;
    setVideoAvatarEnabled: (enabled: boolean) => void;
    onMoveVideoAvatarToPosition: (listener: MoveVideoToPositionListener) => void;
    clearMoveVideoAvatarToPositionListener: () => void;
    trackVideoAvatarPosition: (x: number, y: number) => void;
    setCodyUpCb: (cb: null | CodyCallback) => void;
    setCodyDownCb: (cb: null | CodyCallback) => void;
    setCodyLeftCb: (cb: null | CodyCallback) => void;
    setCodyRightCb: (cb: null | CodyCallback) => void;
    openCodyWizard: (runCody?: boolean) => void;
    mergeIntoSchema: (source: Node, target: Node) => void;
    isEventModelingEnabled: () => boolean;
    makeDraggable: (ele: HTMLElement, nodeToDrop: Node) => void;
}

export const flattenNodeChildren = (nodes: Node[], targetArr?: Node[]): Node[] => {
    if(!targetArr) {
        targetArr = [];
    }

    nodes.forEach(node => {
        targetArr!.push(node);
        if(node.children().count() > 0) {
            flattenNodeChildren(node.children().toJS(), targetArr);
        }
    })

    return targetArr;
}


export const deriveGraphFromMxgraph = (mxgraph: any, editorUi: any): Graph => {
    return new MxGraphWrapper(mxgraph, editorUi);
};

export const makeEmptyGraph = (boardXml?: string): Graph => {
    return new EmptyGraph(boardXml);
};

export const sortNodesByGraphPosition = (a: Node, b: Node): number => {
    if(a.getGeometry().y < b.getGeometry().y - 40) {
        return -1;
    }

    if(a.getGeometry().x < b.getGeometry().x - 40 && a.getGeometry().y < b.getGeometry().y) {
        return -1;
    }

    if(a.getGeometry().x > b.getGeometry().x) {
        return 1;
    }

    if(a.getGeometry().y > b.getGeometry().y) {
        return 1;
    }

    return 0;
};

export const sortNodesByType = (a: Node, b: Node): number => {
    if(a.getType() === NodeType.boundedContext && b.getType() !== NodeType.boundedContext) {
        return -1;
    }

    if(b.getType() === NodeType.boundedContext && a.getType() !== NodeType.boundedContext) {
        return 1;
    }

    if(a.getType() === NodeType.feature && b.getType() !== NodeType.feature) {
        return -1;
    }

    if(b.getType() === NodeType.feature && a.getType() !== NodeType.feature) {
        return 1;
    }

    if(a.getName() < b.getName()) {
        return -1;
    }

    if(b.getName() < a.getName()) {
        return 1;
    }

    return 0;
}

export const convertNodeToJs = (node: Node): RawNodeRecordProps => {
    return {
        id: node.getId(),
        name: node.getName(),
        description: node.getDescription(),
        type: node.getType(),
        link: node.getLink(),
        tags: node.getTags().toJS(),
        layer: node.isLayer(),
        defaultLayer: node.isDefaultLayer(),
        parent: node.getParent()? makeParentNodeRecord(node.getParent() as Node, node) : null,
        // toJSON => shallow copy is important here to keep Node classes intact, with toJS() they would be converted to plain objects, which is not what we want!
        childrenList: node.children().map(makeNodeRecord).toJSON(),
        sourcesList: node.getSources().map(makeConnectedNodeRecord).toJSON(),
        targetsList: node.getTargets().map(makeConnectedNodeRecord).toJSON(),
        geometry: new GraphPointRecord(node.getGeometry()),
        metadata: node.getMetadata(),
        size: new SizeRecord(node.getSize()),
        enabled: node.isEnabled()
    };
}

export const makeNodeFromJs = (data: Partial<RawNodeRecordProps>): Node => {
    const immutableData: Partial<NodeRecordProps> = {};

    if(data.id) {
        immutableData.id = data.id;
    }

    if(data.name) {
        immutableData.name = data.name;
    }

    if(data.description) {
        immutableData.description = data.description;
    }

    if(data.type) {
        immutableData.type = data.type;
    }

    if(data.link) {
        immutableData.link = data.link;
    }

    if(data.tags) {
        immutableData.tags = List(data.tags);
    }

    immutableData.layer = data.layer || false;
    immutableData.defaultLayer = data.defaultLayer || false;

    if(data.parent) {
        immutableData.parent = data.parent;
    }

    if(data.childrenList) {
        immutableData.childrenList = List(data.childrenList);
    }

    if(data.sourcesList) {
        immutableData.sourcesList = List(data.sourcesList);
    }

    if(data.targetsList) {
        immutableData.targetsList = List(data.targetsList);
    }

    if(data.geometry) {
        immutableData.geometry = new GraphPointRecord({x: data.geometry.x, y: data.geometry.y});
    }

    if(data.metadata) {
        immutableData.metadata = data.metadata;
    }

    if(data.size) {
        immutableData.size = new SizeRecord({width: data.size.width, height: data.size.height});
    }

    if(typeof data.enabled !== 'undefined') {
        immutableData.enabled = !!data.enabled;
    }


    return new NodeRecord({...defaultNodeRecordProps, ...immutableData});
}

// tslint:disable-next-line:max-classes-per-file
class GraphPointRecord extends Record({x: 0, y: 0}) implements GraphPoint {};

// tslint:disable-next-line:max-classes-per-file
class SizeRecord extends Record({width: 0, height: 0}) implements Size {};

// tslint:disable-next-line:max-classes-per-file
export class MxGraphElement implements Node {
    private mxcell: any;
    private mxgraphModel: any;
    private mxgraph: any;

    private id: NodeId | null = null;
    private root: boolean | null = null;
    private name: NodeName | null = null;
    private technicalName: NodeName | null = null;
    private description: NodeDescription | null = null;
    private type: NodeType | null = null;
    private tags: List<NodeTag> | null = null;
    private layer: boolean = false;
    private parentCache: Node | null | undefined = undefined;
    private childrenCache: List<Node> | null = null;
    private sourcesCache: List<Node> | null = null;
    private targetsCache: List<Node> | null = null;
    private metadataCache: string | null | undefined = undefined;
    private geoCache: GraphPoint | undefined = undefined;
    private sizeCache: Size | undefined = undefined;
    private deepLinkFactory: DeepLinkFactory | undefined = undefined;

    constructor (mxcell: any, mxgraphModel: any, mxgraph: any, layer: boolean = false, deepLinkFactory?: DeepLinkFactory | undefined) {
        this.mxcell = mxcell;
        this.mxgraphModel = mxgraphModel;
        this.mxgraph = mxgraph;
        this.layer = layer;
        this.root = mxcell.getId() === MXGRAPH_ROOT_UUIDS[1];
        this.deepLinkFactory = deepLinkFactory;
    }

    public getId(): NodeId {
        if(null === this.id) {
            this.id = this.mxcell.getId();
        }
        return this.id as NodeId;
    }

    public getName(): NodeName {
        if(null === this.name) {
            this.name = inspectioUtils.getLabelText(this.mxcell);

            if(this.isDefaultLayer() && this.name === '') {
                this.name = 'Board'
            }
        }
        return this.name as NodeName;
    }

    public getTechnicalName(): NodeName {
        if(null === this.technicalName) {
            const meta = parseNodeMetadata(this);

            this.technicalName = meta.$nodeName || this.getName();
        }

        return this.technicalName as NodeName;
    }

    public changeName(name: NodeName): MxGraphElement {
        const copy = new MxGraphElement(this.mxcell, this.mxgraphModel, this.mxgraph, this.layer, this.deepLinkFactory);
        this.copyInternalProps(copy);
        copy.name = name;
        return copy;
    }

    public getDescription(): NodeDescription {
        if(null === this.description) {
            this.description = inspectioUtils.getLabelSecondaryText(this.mxcell);
        }

        return this.description as NodeDescription;
    }

    public getType(): NodeType {
        if(null !== this.type) {
            return this.type;
        }

        const meta = parseNodeMetadata(this);

        if(meta.$nodeType) {
            this.type = meta.$nodeType;
            return this.type as NodeType;
        }

        let type = inspectioUtils.getTypeInclEdge(this.mxcell);

        if(!type) {
            type = NodeType.misc;
        }

        if(type === HistoryCellType.multiple) {
            type = NodeType.misc;
        }

        if(this.isLayer()) {
            type = NodeType.layer;
        }

        this.type = type;

        return this.type;
    }

    public getLink(): NodeLink | null {
        if(this.isLayer()) {
            return null;
        }

        if(this.root) {
            return null;
        }

        if(!this.deepLinkFactory) {
            return null;
        }

        return this.deepLinkFactory(this.getId());
    }

    public getTags(): List<NodeTag> {
        if(null !== this.tags) {
            return this.tags;
        }

        return List(inspectioUtils.getTags(this.mxcell));
    }

    public getParent(): Node | null {
        if(typeof this.parentCache === 'undefined') {
            this.parentCache = this.isLayer() || this.mxcell.getParent() === null? null : new MxGraphElement(
                this.mxcell.getParent(),
                this.mxgraphModel,
                this.mxgraph,
                this.mxgraphModel.getChildren(this.mxgraphModel.root).includes(this.mxcell.getParent()),
                this.deepLinkFactory
            );
        }

        return this.parentCache;
    }

    public changeParent(parent: Node | null): Node {
        const copy = new MxGraphElement(this.mxcell, this.mxgraphModel, this.mxgraph, this.layer, this.deepLinkFactory);
        this.copyInternalProps(copy);
        copy.parentCache = parent;
        return copy;
    }

    public children(): List<Node> {
        if(null !== this.childrenCache) {
            return this.childrenCache;
        }

        let children: any[] | null = this.mxgraphModel.getChildren(this.mxcell);

        if(!children) {
            children = [];
        }

        const childrenList = List(children);

        this.childrenCache = childrenList.map(child => new MxGraphElement(child, this.mxgraphModel, this.mxgraph, !!this.root, this.deepLinkFactory));

        return this.childrenCache;
    }

    public setChildren(children: List<Node>): Node {
        this.childrenCache = children;
        return this;
    }

    public getSources(): List<Node> {
        if(null !== this.sourcesCache) {
            return this.sourcesCache;
        }

        let sources = List();

        if(!this.mxcell.isVertex() || !this.mxcell.edges) {
            return sources;
        }

        this.mxcell.edges.forEach((edge: any) => {
            if(edge && edge.source && edge.source.getId() !== this.mxcell.getId()) {
                sources = sources.filter(s => s.getId() !== edge.source.getId())
                  .push(new MxGraphElement(edge.source, this.mxgraphModel, this.mxgraph, false, this.deepLinkFactory));
            }
        });

        this.sourcesCache = sources;

        return sources;
    }

    public getTargets(): List<Node> {
        if(null !== this.targetsCache) {
            return this.targetsCache;
        }

        let targets = List();

        if(!this.mxcell.isVertex() || !this.mxcell.edges) {
            return targets;
        }

        this.mxcell.edges.forEach((edge: any) => {
            if(edge && edge.target && edge.target.getId() !== this.mxcell.getId()) {
                targets = targets.filter(t => t.getId() !== edge.target.getId())
                  .push(new MxGraphElement(edge.target, this.mxgraphModel, this.mxgraph, false, this.deepLinkFactory));
            }
        });

        this.targetsCache = targets;
        return targets;
    }

    public isLayer(): boolean {
        return this.layer;
    }

    public isDefaultLayer(): boolean {
        return this.isLayer() && this.mxcell.getId() === MXGRAPH_ROOT_UUIDS[0];
    }

    public getGeometry(): GraphPoint {
        // Only use cache if it was explicitly set with changeGeometry
        if(this.geoCache) {
            return this.geoCache;
        }

        if(!this.mxcell.geometry) {
            return {x:0, y:0};
        }

        return new GraphPointRecord({x: this.mxcell.geometry.x, y: this.mxcell.geometry.y});
    }

    public getSize(): Size {
        // Only use cache if it was explicitly set with changeSize
        if(this.sizeCache) {
            return this.sizeCache;
        }

        if(!this.mxcell.geometry) {
            return {width: 0, height: 0};
        }

        return new SizeRecord({width: this.mxcell.geometry.width, height: this.mxcell.geometry.height});
    }

    public changeGeometry(geometry: GraphPoint): Node {
        const copy = new MxGraphElement(this.mxcell, this.mxgraphModel, this.mxgraph, this.layer, this.deepLinkFactory);
        this.copyInternalProps(copy);
        copy.geoCache = geometry;
        return copy;
    }

    public changeSize(size: Size): Node {
        const copy = new MxGraphElement(this.mxcell, this.mxgraphModel, this.mxgraph, this.layer, this.deepLinkFactory);
        this.copyInternalProps(copy);
        copy.sizeCache = size;
        return copy;
    }

    public getMetadata(): string | null {
        if(typeof this.metadataCache === 'undefined') {
            const meta = inspectioUtils.getMetadata(this.mxcell);
            this.metadataCache = meta? meta : null;
        }

        return this.metadataCache;
    }

    public changeMetadata(metadata: string): MxGraphElement {
        const copy = new MxGraphElement(this.mxcell, this.mxgraphModel, this.mxgraph, this.layer, this.deepLinkFactory);
        this.copyInternalProps(copy);
        copy.metadataCache = metadata;
        return copy;
    }

    public isEnabled(): boolean {
        return this.mxgraph.isCellEnabled(this.mxcell);
    }

    private copyInternalProps (target: MxGraphElement) {
        target.id = this.id;
        target.root = this.root;
        target.name = this.name;
        target.description = this.description;
        target.type = this.type;
        target.tags = this.tags;
        target.layer = this.layer;
        target.parentCache = this.parentCache;
        target.childrenCache = this.childrenCache;
        target.targetsCache = this.targetsCache;
        target.metadataCache = this.metadataCache;
        target.geoCache = this.geoCache;
        target.sizeCache = this.sizeCache;
        target.deepLinkFactory = this.deepLinkFactory;
    }
}

// tslint:disable-next-line:max-classes-per-file
class MxGraphWrapper implements Graph {
    private mxgraph: any;
    private editorUi: any;
    private orgLayerVisibility: {[nodeId: string]: boolean} = {};

    constructor(mxgraph: any, editorUi: any) {
        this.mxgraph = mxgraph;
        this.editorUi = editorUi;
    }

    public createCellId(): string {
        return this.mxgraph.model.createId();
    }

    public getCurrentScale(): number {
        return this.mxgraph.getCurrentScale();
    }

    public getCurrentTranslate(): GraphPoint {
        return this.mxgraph.getCurrentTranslate();
    }

    public onPanning(listener: PanningListener): void {
        this.mxgraph.onPanning(listener);
    }

    public offPanning(listener: PanningListener): void {
        this.mxgraph.offPanning(listener);
    }

    public translateMousePoint(x: number, y: number): GraphPoint {
        return this.mxgraph.translateMousePoint(x, y);
    }

    public onZoom(listener: ZoomListener): void {
        this.mxgraph.onZoom(listener);
    }

    public offZoom(listener: ZoomListener): void {
        this.mxgraph.offZoom(listener);
    }

    public getRoot(): Node {
        const mxmodel = this.mxgraph.getModel();
        return makeNodeRecord(new MxGraphElement(mxmodel.getRoot(), mxmodel, this.mxgraph));
    }

    public openLayersDialog(): void {
        this.editorUi.actions.get('layers').funct();
    }

    public triggerCody(): void {
        this.mxgraph.triggerCody();
    }

    public selectNode(node: Node): boolean {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(!cell) {
            return false;
        }

        this.mxgraph.setSelectionCell(cell);
        return true;
    }

    public hasUserLevelVisibilityChanges(): boolean {
        return Object.keys(this.orgLayerVisibility).length > 0;
    }

    public isVisible(node: Node): boolean {
        const cell = this.mxgraph.getModel().getCell(node.getId());

        return cell && this.mxgraph.getModel().isVisible(cell);
    }

    public setUserVisible(node: Node, visible: boolean): void {
        const cell = this.mxgraph.getModel().getCell(node.getId());

        // User visibility feature is deactivated
        // It allows users to play around with visibility without effecting others, but on the other hand
        // it makes the handling way more complicated and would also require a userElementLock functionality
        // We keep the feature in the code, if too many users complain about the behavior
        // Sync button above elements tree is also hidden at the moment
        if(cell) {
            this.mxgraph.model.beginUpdate();

            try {
                this.mxgraph.model.execute(new mxVisibleChange(this.mxgraph.model, cell, visible));
                if(!inspectioUtils.isContainer(cell) && !node.isLayer()) {
                    const edges: any[] = this.mxgraph.getAllEdges([cell]);

                    edges.forEach(edge => {
                        if(visible) {
                            if(edge.source && edge.source !== cell && this.mxgraph.getModel().isVisible(edge.source)) {
                                this.mxgraph.model.execute(new mxVisibleChange(this.mxgraph.model, edge, visible));
                            }

                            if(edge.target && edge.target !== cell && this.mxgraph.getModel().isVisible(edge.target)) {
                                this.mxgraph.model.execute(new mxVisibleChange(this.mxgraph.model, edge, visible));
                            }
                        } else {
                            this.mxgraph.model.execute(new mxVisibleChange(this.mxgraph.model, edge, visible));
                        }
                    })
                }
            } finally {
                this.mxgraph.model.endUpdate();
            }

            return;

            window.setTimeout(() => {
                if(visible) {
                    if(node.isLayer()) {
                        this.mxgraph.zoom(0.2, true);
                        this.mxgraph.showAll(cell);
                        this.mxgraph.scrollCellsIntoView(this.mxgraph.model.getChildren(cell));
                    } else {
                        this.mxgraph.scrollCellsIntoView([cell]);
                    }
                }
            }, 200);
        }

        if(!this.orgLayerVisibility.hasOwnProperty(cell.getId())) {
            this.orgLayerVisibility[cell.getId()] = this.isVisible(node);
        }

        if(cell) {
            this.mxgraph.enableLazyTextPaint();
            this.mxgraph.model.beginUpdateWithoutChangeNotifications();

            try {
                this.mxgraph.getModel().setVisible(cell, visible);
            } finally {
                this.mxgraph.model.endUpdateWithoutChangeNotifications();
            }

            this.mxgraph.disableLazyTextPaint();
        }

        window.setTimeout(() => {
            if(visible) {
                if(node.isLayer()) {
                    this.mxgraph.zoom(0.2, true);
                    this.mxgraph.showAll(cell);
                    this.mxgraph.scrollCellsIntoView(this.mxgraph.model.getChildren(cell));
                } else {
                    this.mxgraph.scrollCellsIntoView([cell]);
                }
            }
        }, 200);
    }

    public applyVisibilityGlobally(): void {
        if(this.hasUserLevelVisibilityChanges()) {
            this.mxgraph.model.beginUpdate();

            try {
                for (const cellId in this.orgLayerVisibility) {
                    if(this.orgLayerVisibility.hasOwnProperty(cellId)) {
                        const cell = this.mxgraph.model.getCell(cellId);

                        if(cell) {
                            this.mxgraph.model.execute(new mxVisibleChange(this.mxgraph.model, cell, this.mxgraph.model.isVisible(cell)));
                        }
                    }
                }

                this.orgLayerVisibility = {};
            } finally {
                this.mxgraph.model.endUpdate();
            }
        }
    }

    public isLocked(node: Node): boolean {
        const cell = this.mxgraph.getModel().getCell(node.getId());

        if(!cell) {
            return false;
        }

        const state = this.mxgraph.view.getState(cell);
        const style = (state != null) ? state.style : this.mxgraph.getCellStyle(cell);
        return  mxUtils.getValue(style, 'locked', '0').toString() === '1';
    }

    public setLocked(node: Node, locked: boolean): void {
        const cell = this.mxgraph.getModel().getCell(node.getId());

        if(!cell) {
            return;
        }

        if(node.getType() === NodeType.feature || node.getType() === NodeType.boundedContext) {
            this.mxgraph.setContainerLocked(cell, locked);
            return;
        }

        const state = this.mxgraph.view.getState(cell);
        const style = (state != null) ? state.style : this.mxgraph.getCellStyle(cell);

        this.mxgraph.getModel().beginUpdate();
        try {
            this.mxgraph.setCellStyles('locked', locked? '1' : null, [cell]);
        } finally {
            this.mxgraph.getModel().endUpdate();
        }

        if (locked)
        {
            this.mxgraph.removeSelectionCells(this.mxgraph.getModel().getDescendants(cell));
        }
    }

    public deleteNode(node: Node): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            this.mxgraph.removeCells([cell], true);
        }
    }

    public addLayer(name: string): void {
        if (this.mxgraph.isEnabled())
        {
            this.mxgraph.model.beginUpdate();

            try
            {
                const cell = this.mxgraph.addCell(new mxCell(name), this.mxgraph.model.root);
                this.mxgraph.setDefaultParent(cell);
            }
            finally
            {
                this.mxgraph.model.endUpdate();
            }
        }
    }

    public deleteLayer(node: Node): void {
        if(node.isDefaultLayer()) {
            throw Error("The default layer cannot be deleted!");
        }

        const cell = this.mxgraph.model.getCell(node.getId());
        const isActiveLayer = this.isActiveLayer(node);

        this.mxgraph.model.beginUpdate();
        try
        {
            const index = this.mxgraph.model.root.getIndex(cell);
            this.mxgraph.removeCells([cell], false);

            if(isActiveLayer) {
                if (index > 0 && index <= this.mxgraph.model.getChildCount(this.mxgraph.model.root))
                {
                    this.mxgraph.setDefaultParent(this.mxgraph.model.getChildAt(this.mxgraph.model.root, index - 1));
                }
                else
                {
                    this.mxgraph.setDefaultParent(null);
                }
            }
        }
        finally
        {
            this.mxgraph.model.endUpdate();
        }
    }

    public isActiveLayer(node: Node): boolean {
        return this.mxgraph.getDefaultParent().getId() === node.getId();
    }

    public setAsActiveLayer(node: Node): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            this.mxgraph.model.beginUpdate();

            try {
                this.mxgraph.setDefaultParent(cell);
            } finally {
                this.mxgraph.model.endUpdate();
            }
        }
    }

    public setDefaultLayerAsActive(): void {
        this.mxgraph.setDefaultParent(null);
    }

    public highlightLayer(node: Node): void {
        const cell = this.mxgraph.getModel().getCell(node.getId());

        if(cell && this.mxgraph.model.getChildren(cell)) {
            this.mxgraph.scrollCellsIntoView(this.mxgraph.model.getChildren(cell));
        }
    }

    public addNode(node: Node): void {
        if(node.isLayer()) {
            this.addLayer(node.getName());
            return;
        }

        if(node.getType() === HistoryCellType.edge) {
            this.addEdge(node);
            return;
        }

        this.addVertex(node);
    }

    public getNode(nodeId: string): Node | null {
        const cell = this.mxgraph.model.getCell(nodeId);

        if(!cell) {
            return null;
        }

        return new MxGraphElement(cell, this.mxgraph.model, this.mxgraph, this.mxgraph.model.isLayer(cell));
    }

    public addNodeNextToAnother(newNode: Node, existingNode: Node, position: NextToPosition, margin: number | {x: number, y: number}, center?: boolean): void {
        this.addNode(newNode);
        this.moveNodeNextToAnother(newNode, existingNode, position, margin, center);
    }

    public moveNodeNextToAnother(node: Node, existingNode: Node, position: NextToPosition, margin: number | {x: number, y: number}, center?: boolean): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(!cell) {
            console.error("[MxGraphWrapper] Tried to move node next to another, but the corresponding cell is not returned by mxgraph. Something seems to be wrong", convertNodeToJs(node));
            return;
        }

        const cellSize = {width: cell.getGeometry().width, height: cell.getGeometry().height};

        const relativeGeo = this.getGeometryRelativeToElement(existingNode, position, cellSize, margin, center);

        if (existingNode.getParent() && cell.getParent().getId() !== existingNode.getParent()!.getId()) {
            const parentCell = this.mxgraph.model.getCell(existingNode.getParent()!.getId());

            if(parentCell) {
                this.mxgraph.model.add(parentCell, cell);
            }
        }

        // First, make sure that cell is at point 0,0 because move works with relative coordinates
        if(node.getGeometry().x > 0 || node.getGeometry().y > 0) {
            this.mxgraph.moveCells([cell], -1 * node.getGeometry().x, -1 * node.getGeometry().y, false);
        }

        this.mxgraph.moveCells([cell], relativeGeo.x, relativeGeo.y, false);
    }

    public changeNodeName(node: Node, newName: string): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            const oldDesc = inspectioUtils.getLabelSecondaryText(cell);

            this.mxgraph.cellLabelChanged(cell, inspectioUtils.joinLabelParts(newName, oldDesc));
        }
    }

    public changeNodeDescription(node: Node, description: NodeDescription): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            const oldName = inspectioUtils.getLabelText(cell);

            this.mxgraph.cellLabelChanged(cell, inspectioUtils.joinLabelParts(oldName, description));
        }
    }

    public changeNodeLabel(node: Node, label: NodeLabel): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            this.mxgraph.cellLabelChanged(cell, label);
        }
    }

    public changeNodeMetadata(node: Node, metadata: NodeMetadata, doNotUpdateSimilarNodes?: boolean): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            this.mxgraph.model.beginUpdate();
            try {
                this.mxgraph.setAttributeForCell(cell, 'metadata', metadata);
                const cellState = this.mxgraph.getCellState(cell);
                this.mxgraph.cellRenderer.redraw(cellState, false, true);
            } catch (e) {
                console.error(e);
            } finally {
                this.mxgraph.model.endUpdate();
            }

            this.mxgraph.triggerChangeActiveGraphElementIfIsActive(cell);

            if(doNotUpdateSimilarNodes) {
                return;
            }

            if(node.getTags().contains(ispConst.TAG_CONNECTED)) {
                const elementsTree = createTreeFromGraph(this);

                const similarNodes = elementsTree.getSimilarElements(node)
                  .filter(ele => ele.getTags().contains(ispConst.TAG_CONNECTED));

                similarNodes.forEach(sn => this.changeNodeMetadata(sn, metadata, true));
            }
        }
    }

    public changeNodeTags(node: Node, tags: List<NodeTag>): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            this.mxgraph.model.beginUpdate();
            try {
                this.mxgraph.setAttributeForCell(cell, ispConst.TAGS_ATTR, tags.join(","));
                const cellState = this.mxgraph.getCellState(cell);
                this.mxgraph.cellRenderer.redraw(cellState, false, true);
            } catch (e) {
                console.error(e);
            } finally {
                this.mxgraph.model.endUpdate();
            }
        }
    }

    public resizeNode(node: Node, size: Size): void {
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell && cell.getGeometry()) {
            const bounds = new mxRectangle(
              node.getGeometry().x,
              node.getGeometry().y,
              size.width,
              size.height
            )

            const positionChanged = cell.getGeometry().x !== bounds.x || cell.getGeometry().y !== bounds.y;

            try {
                this.mxgraph.model.beginUpdate();

                if(positionChanged) {
                    const dx = cell.getGeometry().x - bounds.x;
                    const dy = cell.getGeometry().y - bounds.y;

                    const vertexHandler = new mxVertexHandler(this.mxgraph.view.getState(cell));
                    vertexHandler.moveChildren(cell, dx, dy);
                    vertexHandler.destroy();
                }

                this.mxgraph.resizeCell(cell, bounds, false);
            } catch (e) {
                console.error(e);
            } finally {
                this.mxgraph.model.endUpdate();
            }
        }
    }

    public moveNode(node: Node, toPosition: GraphPoint): void {
        const dx = node.getGeometry().x - toPosition.x;
        const dy = node.getGeometry().y - toPosition.y;

        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            this.mxgraph.moveCells([cell], dx, dy, false);
        }
    }

    public switchParent(node: Node, parent: Node | null): void {
        const parentCell = parent ? this.mxgraph.model.getCell(parent.getId()) : null;
        const cell = this.mxgraph.model.getCell(node.getId());

        if(cell) {
            const childGeometry = mxUtils.clone(cell.getGeometry());

            if(cell.parent && cell.parent.getGeometry() && (!parentCell.parent || parentCell.parent.getId() !== cell.parent.getId())) {
                childGeometry.translate(cell.parent.getGeometry().x, cell.parent.getGeometry().y);
            }

            if(parentCell) {
                childGeometry.translate(parentCell.getGeometry().x * -1, parentCell.getGeometry().y * -1);
            }

            try {
                this.mxgraph.model.beginUpdate();

                this.mxgraph.model.add(parentCell, cell);
                this.mxgraph.model.setGeometry(cell, childGeometry);
            } catch (e) {
                console.error(e);
            } finally {
                this.mxgraph.model.endUpdate();
            }
        }
    }

    public connectNodes(source: Node, target: Node, connectionLabel?: string, edgePoints?: GraphPoint[], invertCurve?: boolean): void {
        // @TODO: handle connectionLabel

        const parent = source.getParent() === null || target.getParent() === null || source.getParent() !== target.getParent()
            ? this.mxgraph.getDefaultParent()
            : this.mxgraph.model.getCell(source.getParent()!.getId());

        const edge = this.mxgraph.insertEdge(parent, null, '', this.mxgraph.model.getCell(source.getId()), this.mxgraph.model.getCell(target.getId()));

        if(!edgePoints) {
            const sourceGeo = this.mxgraph.getModel().getGeometry(this.mxgraph.model.getCell(source.getId()));
            const targetGeo = this.mxgraph.getModel().getGeometry(this.mxgraph.model.getCell(target.getId()));


            if(sourceGeo.x !== targetGeo.x && sourceGeo.y !== targetGeo.y) {
                let pointX = 0;

                edgePoints = [];

                if(!this.isEventModelingEnabled()) {
                    if(targetGeo.x > sourceGeo.x) {
                        const sourceRightCorner = sourceGeo.x + sourceGeo.width;
                        pointX = sourceRightCorner + ((targetGeo.x - sourceRightCorner) / 2);
                    } else {
                        const targetRightCorner = targetGeo.x + targetGeo.width;
                        pointX = targetRightCorner + ((sourceGeo.x - targetRightCorner) / 2);
                    }

                    edgePoints.push(new mxPoint(pointX, (sourceGeo.y + sourceGeo.height / 2)));
                    edgePoints.push(new mxPoint(pointX, (targetGeo.y + targetGeo.height / 2)));
                } else {
                    if(invertCurve) {
                        pointX = sourceGeo.x + (sourceGeo.width / 2);

                        edgePoints.push(new mxPoint(pointX, (targetGeo.y + targetGeo.height / 2)));
                    } else {
                        pointX = targetGeo.x + (targetGeo.width / 2);

                        edgePoints.push(new mxPoint(pointX, (sourceGeo.y + sourceGeo.height / 2)));
                    }
                }
            }
        }

        if(edgePoints && edgePoints.length) {
            const geo = this.mxgraph.getModel().getGeometry(edge) || {points: []};
            geo.points = edgePoints;
            this.mxgraph.getModel().setGeometry(edge, geo);
            const edgeState = this.mxgraph.getCellState(edge);
            this.mxgraph.view.resetValidationState();
            this.mxgraph.view.invalidate(edge);
            this.mxgraph.cellRenderer.redraw(edgeState, false, true);
        }
    }

    public highlightNodes(nodes: Node[]): void {
        // Do nothing
    }

    public copySelectionToActiveLayer(): boolean {
        if (this.mxgraph.isEnabled() && !this.mxgraph.isSelectionEmpty())
        {
            this.mxgraph.moveCells(this.mxgraph.getSelectionCells(), 0, 0, false, this.mxgraph.getDefaultParent());
            return true;
        }

        return false;
    }

    public changeFeatureStatus(feature: Node, status: "important" | "planned" | "ready" | "deployed"): void {
        if(feature.getType() !== NodeType.feature) {
            return;
        }

        this.selectNode(feature);

        const action = this.mxgraph.editorUi.actions.get(`tag${names(status).className}`);

        if(action) {
            action.funct();
        }
    }

    public getFeatureTaskLink(feature: Node): string | null {
        if(feature.getType() !== NodeType.feature) {
            return null;
        }

        const cell = this.mxgraph.model.getCell(feature.getId());

        return this.mxgraph.getFeatureTaskLink(cell);
    }

    public setFeatureTaskLink(feature: Node, link: string | null): void {
        if(feature.getType() !== NodeType.feature) {
            return;
        }

        const cell = this.mxgraph.model.getCell(feature.getId());

        if(!cell) {
            return;
        }

        this.mxgraph.setFeatureTaskLink(cell, link);
    }

    public exportXmlSnapshot(): string {
        // 1. check if we need to reset layer visibility to not override global settings
        const userLayerVisibility: {[nodeId: string]: boolean} = {};

        if(this.hasUserLevelVisibilityChanges()) {
            this.mxgraph.model.beginUpdateWithoutChangeNotifications();

            try {
                for (const cellId in this.orgLayerVisibility) {
                    if(this.orgLayerVisibility.hasOwnProperty(cellId)) {
                        const cell = this.mxgraph.model.getCell(cellId);

                        if(cell) {
                            const layerVisibleForUser = this.mxgraph.model.isVisible(cell);

                            if(layerVisibleForUser !== this.orgLayerVisibility[cellId]) {
                                userLayerVisibility[cellId] = layerVisibleForUser;
                                this.mxgraph.model.execute(new mxVisibleChange(this.mxgraph.model, cell, this.orgLayerVisibility[cellId]));
                            }
                        }
                    }
                }
            } finally {
                this.mxgraph.model.endUpdateWithoutChangeNotifications();
            }
        }

        // 2. Export Graph Xml

        const enc = new mxCodec(mxUtils.createXmlDocument());
        const node = enc.encode(this.mxgraph.getModel());

        if (this.mxgraph.view.translate.x !== 0 || this.mxgraph.view.translate.y !== 0)
        {
            node.setAttribute('dx', Math.round(this.mxgraph.view.translate.x * 100) / 100);
            node.setAttribute('dy', Math.round(this.mxgraph.view.translate.y * 100) / 100);
        }

        node.setAttribute('grid', (this.mxgraph.isGridEnabled()) ? '1' : '0');
        node.setAttribute('gridSize', this.mxgraph.gridSize);
        node.setAttribute('guides', (this.mxgraph.graphHandler.guidesEnabled) ? '1' : '0');
        node.setAttribute('tooltips', (this.mxgraph.tooltipHandler.isEnabled()) ? '1' : '0');
        node.setAttribute('connect', (this.mxgraph.connectionHandler.isEnabled()) ? '1' : '0');
        node.setAttribute('arrows', (this.mxgraph.connectionArrowsEnabled) ? '1' : '0');
        node.setAttribute('fold', (this.mxgraph.foldingEnabled) ? '1' : '0');
        node.setAttribute('page', (this.mxgraph.pageVisible) ? '1' : '0');
        node.setAttribute('pageScale', this.mxgraph.pageScale);
        node.setAttribute('pageWidth', this.mxgraph.pageFormat.width);
        node.setAttribute('pageHeight', this.mxgraph.pageFormat.height);

        if (this.mxgraph.background != null)
        {
            node.setAttribute('background', this.mxgraph.background);
        }

        const xml = mxUtils.getXml(node);

        // 3. Now set layer visibility back to what user currently sees
        this.mxgraph.model.beginUpdateWithoutChangeNotifications();

        try {
            for (const layerId in this.orgLayerVisibility) {
                if(this.orgLayerVisibility.hasOwnProperty(layerId)) {
                    const layer = this.mxgraph.model.getCell(layerId);

                    if(layer) {
                        this.mxgraph.model.execute(new mxVisibleChange(this.mxgraph.model, layer, userLayerVisibility[layerId]));
                    }
                }
            }
        } finally {
            this.mxgraph.model.endUpdateWithoutChangeNotifications();
        }

        return xml;
    }

    public onSelectionChanged(listener: (hasSelection: boolean) => void): void {
        this.mxgraph.selectionModel.addListener(mxEvent.CHANGE, () => {
            if(this.mxgraph.isSelectionEmpty()) {
                listener(false);
            } else {
                listener(true);
            }
        });
    }

    public setCockpitBaseUrl(baseUrl: string): void {
        this.mxgraph.setCockpitBaseUrl(baseUrl);
    }

    public focus(): void {
        this.mxgraph.container.focus();
    }

    public clearMoveVideoAvatarToPositionListener(): void {
        this.mxgraph.clearMoveVideoAvatarToPositionListener();
    }

    public onMoveVideoAvatarToPosition(listener: MoveVideoToPositionListener): void {
        this.mxgraph.onMoveVideoAvatarToPosition(listener);
    }

    public setVideoAvatarEnabled(enabled: boolean): void {
        this.mxgraph.setVideoAvatarEnabled(enabled);
    }

    public trackVideoAvatarPosition(x: number, y: number): void {
        this.mxgraph.trackVideoAvatarPosition(x,y);
    }

    public setCodyDownCb(cb: CodyCallback | null): void {
        this.mxgraph.setCodyDownCb(cb);
    }

    public setCodyLeftCb(cb: CodyCallback | null): void {
        this.mxgraph.setCodyLeftCb(cb);
    }

    public setCodyRightCb(cb: CodyCallback | null): void {
        this.mxgraph.setCodyRightCb(cb);
    }

    public setCodyUpCb(cb: CodyCallback | null): void {
        this.mxgraph.setCodyUpCb(cb);
    }

    public openCodyWizard(runCody?: boolean): void {
        const action = 'codywizard' + (runCody ? '_run' : '');
        this.editorUi.actions.get(action).funct();
    }

    public mergeIntoSchema(source: Node, target: Node): void {
        if(source.getType() !== NodeType.document) {
            return;
        }

        const sourceMeta = getVoMetadata(source);

        let targetMeta: ValueObjectMetadata | CommandMetadata | EventMetadata | null = null;

        switch (target.getType()) {
            case NodeType.document:
                targetMeta = getVoMetadata(target);
                break;
            case NodeType.event:
                targetMeta = getEventMetadata(target);
                break;
            case NodeType.command:
                targetMeta = getCommandMetadata(target);
                break;
        }

        if(!targetMeta) {
            return;
        }

        let sourceSchema = sourceMeta.schema;

        if(!sourceSchema || sourceSchema.isEmpty()) {
            sourceSchema = this.getSchemaFromDescription(source);
        }

        const targetSchema = targetMeta.schema || Schema.fromString('{}');

        if(!targetSchema.isObject()) {
            return;
        }

        if(sourceSchema.isObject()) {
            const elementsTree = createTreeFromGraph(this);

            if(elementsTree.getSimilarElements(source).count() > 0) {
                const refName = getSchemaRefName(source, sourceMeta, true);
                sourceSchema = Schema.fromString(refName);
            }
        }

        let propName = source.getName();
        let required = true;

        if(propName[propName.length - 1] === '?') {
            required = false;
            propName = propName.slice(0, propName.length - 1);
        }

        targetSchema.setObjectProperty(names(propName).propertyName, sourceSchema, required);
        targetMeta.schema = targetSchema;

        this.changeNodeMetadata(target, JSON.stringify(targetMeta, null, 2));
        this.deleteNode(source);
    }

    public isEventModelingEnabled(): boolean {
        return !!this.mxgraph.eventModelingEnabled;
    }

    public makeDraggable(ele: HTMLElement, nodeToDrop: Node): void {
        const existingCell = this.mxgraph.getModel().getCell(nodeToDrop.getId());

        if(this.editorUi.sidebar) {
            const sidebar = this.editorUi.sidebar;
            const bounds = {
                x: 0,
                y: 0,
                width: nodeToDrop.getSize().width,
                height: nodeToDrop.getSize().height,
            }

            sidebar.createDragSource(
              ele,
              sidebar.createDropHandler([existingCell], false, true, bounds, undefined, undefined, true),
              sidebar.createDragPreview(bounds.width, bounds.height, nodeToDrop.getType()),
              [existingCell],
              bounds
            );
        }
    }

    private addVertex(node: Node): void
    {
        const existingCell = this.mxgraph.getModel().getCell(node.getId());

        if(existingCell) {
            console.warn("[BoardAgent] Skipping element. A cell with same id is already on the board!", convertNodeToJs(node));
            return;
        }

        const attributes: {[name: string]: string} = {};

        if(node.getTags().count()) {
            attributes[ispConst.TAGS_ATTR] = node.getTags().join(",");
        }


        const cellValue = inspectioUtils.createCellXmlValue(
            node.getType(),
            node.getName(),
            node.getDescription(),
            node.getMetadata(),
            attributes
        )

        const stylesheet = this.mxgraph.getStylesheet();

        const style = stylesheet.getCellStyle(node.getType());

        let size = {width: node.getSize().width, height: node.getSize().height};

        if(size.width === 0) {
            if(style.minWidth) {
                size.width = parseInt(style.minWidth.toString(), undefined);
                size.height = parseInt(style.minHeight.toString(), undefined);
            } else {
                size = {width: 160, height: 100};
            }
        }

        const pos = node.getGeometry();

        // @TODO: check that geo is relative if parent is given

        const cell = new mxCell(cellValue, new mxGeometry(pos.x, pos.y, size.width, size.height), node.getType());

        cell.setId(node.getId());
        cell.setVertex(true);

        // Ensure that cell has state
        this.mxgraph.execWithUnscaledView(() => {
            const state = this.mxgraph.view.getState(cell, true);
            this.mxgraph.view.updateCellState(state);
        })

        const preferredSize = this.mxgraph.getPreferredSizeForCell(cell);

        if(size.height !== preferredSize.height) {
            cell.setGeometry(new mxGeometry(pos.x, pos.y, preferredSize.width, preferredSize.height));
        }


        const parent = node.getParent() ? this.mxgraph.getModel().getCell(node.getParent()!.getId()) : null;

        if(inspectioUtils.isContainer(cell)) {
            inspectioUtils.initContainer(cell, this.mxgraph, true);
        }

        this.mxgraph.importCells([cell], 0, 0, parent);
    }

    private addEdge(node: Node): void
    {
        // @TODO: add edge, cell.setEdge(true);
    }

    private getGeometryRelativeToElement(element: Node, pos: NextToPosition, size: NodeSize, margin: number | {x: number, y: number}, center?: boolean): GraphPoint {
        const eleGeo = element.getGeometry();

        const eleSize = element.getSize();

        const xMargin = typeof margin === "object" ? margin.x : margin;
        const yMargin = typeof margin === "object" ? margin.y : margin;

        if(typeof center === 'undefined') {
            center = true;
        }

        const centeredX = eleSize.width === 0 || size.width === 0
            ? eleGeo.x
            : eleGeo.x + (eleSize.width / 2 ) - (size.width / 2);

        const centeredY = eleSize.height === 0 || size.height === 0
            ? eleGeo.y
            : eleGeo.y + (eleSize.height / 2) - (size.height / 2);

        switch (pos) {
            case NextToPosition.left:
                return {
                    x: eleGeo.x - xMargin - size.width,
                    y: center? centeredY : eleGeo.y
                }
            case NextToPosition.right:
                return {
                    x: eleGeo.x + eleSize.width + xMargin,
                    y: center? centeredY : eleGeo.y
                }
            case NextToPosition.above:
                return {
                    x: center? centeredX : eleGeo.x,
                    y: eleGeo.y - yMargin - size.height,
                }
            case NextToPosition.below:
                return {
                    x: center? centeredX : eleGeo.x,
                    y: eleGeo.y + eleSize.height + yMargin,
                }
            case NextToPosition.aboveLeft:
                return {
                    x: eleGeo.x - xMargin - size.width,
                    y: eleGeo.y - yMargin - size.height,
                }
            case NextToPosition.aboveRight:
                return {
                    x: eleGeo.x + eleSize.width + xMargin,
                    y: eleGeo.y - yMargin - size.height,
                }
            case NextToPosition.belowLeft:
                return {
                    x: eleGeo.x - xMargin - size.width,
                    y: eleGeo.y + eleSize.height + yMargin,
                }
            case NextToPosition.belowRight:
                return {
                    x: eleGeo.x + eleSize.width + xMargin,
                    y: eleGeo.y + eleSize.height + yMargin,
                }
        }

        return {x: 0, y: 0};
    }

    private getSchemaFromDescription(element: Node): Schema {
        const desc = element.getDescription();

        const schema = Schema.fromString(desc);

        if(schema.isEmpty()) {
            return Schema.fromString('string')
        }

        return schema;
    }
}

// tslint:disable-next-line:max-classes-per-file
export class EmptyLayer implements Node {
    public getId(): NodeId {
        return '__EMPTY_LAYER__';
    }

    public getName(): NodeName {
        return 'Empty Layer';
    }

    public getTechnicalName(): NodeName {
        return this.getName();
    }

    public getDescription(): NodeDescription {
        return '';
    }

    public getType(): NodeType {
        return NodeType.misc;
    }

    public getLink(): NodeLink | null {
        return null;
    }

    public getTags(): List<NodeTag> {
        return List();
    }

    public isLayer(): boolean {
        return true;
    }

    public isDefaultLayer(): boolean {
        return false;
    }

    public getParent(): Node | null {
        return null;
    }

    public changeParent(parent: Node | null): Node {
        // do nothing
        return this;
    }



    public children(): List<Node> {
        return List();
    }

    public getSources(): List<Node> {
        return List();
    }

    public getTargets(): List<Node> {
        return List();
    }

    public getMetadata(): string | null {
        return null;
    }

    public setChildren(children: List<Node>): Node {
        return this;
    }

    public getGeometry(): GraphPoint {
        return {x: 0, y: 0};
    }

    public getSize(): Size {
        return {width: 0, height: 0};
    }

    public changeName(name: NodeName): EmptyLayer {
        // do nothing
        return this;
    }

    public changeMetadata(metadata: string): EmptyLayer {
        // do nothing
        return this;
    }

    public isEnabled(): boolean {
        return true;
    }

    public changeGeometry(geometry: GraphPoint): Node {
        // do nothing
        return this;
    }

    public changeSize(size: Size): Node {
        // do nothing
        return this;
    }


}

// tslint:disable-next-line:max-classes-per-file
class EmptyGraph implements Graph {
    private boardXml: string;

    constructor(boardXml?: string) {
        if(!boardXml) {
            boardXml = '';
        }

        this.boardXml = boardXml;
    }

    public createCellId(): string {
        return shortUUID().new();
    }

    public getCurrentScale(): number {
        return 1.0;
    }

    public getCurrentTranslate(): GraphPoint {
        return {x: 0, y: 0};
    }

    public onPanning(listener: PanningListener): void {
        // no op
    }

    public offPanning(listener: PanningListener): void {
        // no op
    }

    public translateMousePoint(x: number, y: number): GraphPoint {
        return {x,y};
    }

    public onZoom(listener: ZoomListener): void {
        // no op
    }

    public offZoom(listener: ZoomListener): void {
        // no op
    }

    public getRoot(): Node {
        return new EmptyLayer();
    }

    public openLayersDialog(): void {
        // do nothing
    }

    public triggerCody(): void {
        // do nothing
    }

    public selectNode(node: Node): boolean {
        return false;
    }

    public hasUserLevelVisibilityChanges(): boolean {
        return false;
    }

    public isVisible(node: Node): boolean {
        return false;
    }

    public setUserVisible(node: Node, visible: boolean): void {
        // Do nothing
    }

    public applyVisibilityGlobally(): void {
        // Do nothing
    }

    public isLocked(node: Node): boolean {
        return false;
    }

    public setLocked(node: Node, locked: boolean): void {
        // Do nothing
    }

    public addLayer(name: string): void {
        // Do nothing
    }

    public deleteLayer(node: Node): void {
        // Do nothing
    }

    public isActiveLayer(node: Node): boolean {
        return false;
    }

    public setAsActiveLayer(node: Node): void {
        // Do nothing
    }

    public setDefaultLayerAsActive(): void {
        // Do nothing
    }

    public highlightLayer(node: Node): void {
        // Do nothing
    }

    public addNode(node: Node): void {
        // Do nothing
    }

    public getNode(nodeId: string): Node | null {
        return null;
    }

    public addNodeNextToAnother(newNode: Node, existingNode: Node, position: NextToPosition, margin: number | {x: number, y: number}, center?: boolean): void {
        // Do nothing
    }

    public moveNodeNextToAnother(node: Node, existingNode: Node, position: NextToPosition, margin: number | {x: number, y: number}, center?: boolean): void {
        // Do nothing
    }

    public deleteNode(node: Node): void {
        // Do nothing
    }

    public changeNodeName(node: Node, newName: string): void {
        // Do nothing
    }

    public changeNodeDescription(node: Node, description: NodeDescription): void {
        // Do nothing
    }

    public changeNodeLabel(node: Node, label: NodeLabel): void {
        // Do nothing
    }

    public changeNodeMetadata(node: Node, metadata: NodeMetadata): void {
        // Do nothing
    }

    public changeNodeTags(node: Node, tags: List<NodeTag>): void {
        // Do nothing
    }

    public resizeNode(node: Node, size: Size): void {
        // Do nothing
    }

    public moveNode(node: Node, toPosition: GraphPoint): void {
        // Do nothing
    }

    public switchParent(node: Node, parent: Node | null): void {
        // Do nothing
    }

    public connectNodes(source: Node, target: Node, connectionLabel?: string, edgePoints?: GraphPoint[], invertCurve?: boolean): void {
        // Do nothing
    }

    public highlightNodes(nodes: Node[]): void {
        // Do nothing
    }

    public copySelectionToActiveLayer(): boolean {
        return false;
    }

    public changeFeatureStatus(feature: Node, status: "important" | "planned" | "ready" | "deployed"): void {
        // Do nothing
    }

    public getFeatureTaskLink(feature: Node): string | null {
        return null;
    }

    public setFeatureTaskLink(feature: Node, link: string | null): void {
        // Do nothing
    }

    public exportXmlSnapshot(): string {
        return this.boardXml;
    }

    public onSelectionChanged(listener: (hasSelection: boolean) => void): void {
        // Do nothing
    }

    public setCockpitBaseUrl(baseUrl: string): void {
        // Do nothing
    }

    public focus(): void {
        // Do nothing
    }

    public clearMoveVideoAvatarToPositionListener(): void {
        // Do nothing
    }

    public onMoveVideoAvatarToPosition(listener: MoveVideoToPositionListener): void {
        // Do nothing
    }

    public setVideoAvatarEnabled(enabled: boolean): void {
        // Do nothing
    }

    public trackVideoAvatarPosition(x: number, y: number): void {
        // Do nothing
    }

    public setCodyDownCb(cb: CodyCallback | null): void {
        // Do nothing
    }

    public setCodyLeftCb(cb: CodyCallback | null): void {
        // Do nothing
    }

    public setCodyRightCb(cb: CodyCallback | null): void {
        // Do nothing
    }

    public setCodyUpCb(cb: CodyCallback | null): void {
        // Do nothing
    }

    public openCodyWizard(runCody?: boolean): void {
        // Do nothing
    }

    public mergeIntoSchema(source: Node, target: Node): void {
        // Do nothing
    }

    public isEventModelingEnabled(): boolean {
        return true;
    }

    public makeDraggable(ele: HTMLElement, nodeToDrop: Node): void {
        // Do nothing
    }
}

interface NodeRecordProps {
    id: NodeId;
    name: NodeName;
    description: NodeDescription;
    type: NodeType;
    link: NodeLink | null;
    tags: List<NodeTag>;
    layer: boolean;
    defaultLayer: boolean;
    parent: Node | null;
    childrenList: List<Node>;
    sourcesList: List<Node>;
    targetsList: List<Node>;
    geometry: GraphPoint;
    size: Size;
    metadata: string | null;
    enabled: boolean;
}

interface RawNodeRecordProps {
    id: NodeId;
    name: NodeName;
    description: NodeDescription;
    type: NodeType;
    link: NodeLink | null;
    tags: string[];
    layer: boolean;
    defaultLayer: boolean;
    parent: Node | null;
    childrenList: Node[];
    sourcesList: Node[];
    targetsList: Node[];
    geometry: {x: number, y: number};
    size: {width: number, height: number};
    metadata: string | null;
    enabled: boolean;
}

const defaultNodeRecordProps: NodeRecordProps = {
    id: '',
    name: '',
    description: '',
    type: NodeType.misc,
    link: null,
    tags: List(),
    layer: false,
    defaultLayer: false,
    parent: null,
    childrenList: List(),
    sourcesList: List(),
    targetsList: List(),
    geometry: {x:0, y:0},
    size: {width: 0, height: 0},
    metadata: null,
    enabled: true,
};

const makeNodeRecord = (node: Node): NodeRecord => {
    return new NodeRecord({
        id: node.getId(),
        name: node.getName(),
        description: node.getDescription(),
        type: node.getType(),
        link: node.getLink(),
        tags: node.getTags(),
        layer: node.isLayer(),
        defaultLayer: node.isDefaultLayer(),
        parent: node.getParent()? makeParentNodeRecord(node.getParent() as Node, node) : null,
        childrenList: node.children().map(makeNodeRecord),
        sourcesList: node.getSources().map(makeConnectedNodeRecord),
        targetsList: node.getTargets().map(makeConnectedNodeRecord),
        geometry: new GraphPointRecord(node.getGeometry()),
        size: new SizeRecord(node.getSize()),
        metadata: node.getMetadata(),
        enabled: node.isEnabled(),
    });
};

const makeConnectedNodeRecord = (node: Node): NodeRecord => {
    return new NodeRecord({
        id: node.getId(),
        name: node.getName(),
        description: node.getDescription(),
        type: node.getType(),
        link: node.getLink(),
        tags: node.getTags(),
        layer: node.isLayer(),
        defaultLayer: node.isDefaultLayer(),
        parent: node.getParent()? makeParentNodeRecord(node.getParent() as Node, node) : null,
        childrenList: node.children().map(makeNodeRecord),
        sourcesList: List(),
        targetsList: List(),
        geometry: new GraphPointRecord(node.getGeometry()),
        size: new SizeRecord(node.getSize()),
        metadata: node.getMetadata(),
        enabled: node.isEnabled(),
    });
};

const makeParentNodeRecord = (parent: Node, referencingChild: Node): NodeRecord => {
    return new NodeRecord({
        id: parent.getId(),
        name: parent.getName(),
        description: parent.getDescription(),
        type: parent.getType(),
        link: parent.getLink(),
        tags: parent.getTags(),
        layer: parent.isLayer(),
        defaultLayer: parent.isDefaultLayer(),
        parent: parent.getParent()? makeParentNodeRecord(parent.getParent() as Node, parent) : null,
        childrenList: List(),
        sourcesList: List(),
        targetsList: List(),
        geometry: new GraphPointRecord(parent.getGeometry()),
        size: new SizeRecord(parent.getSize()),
        metadata: parent.getMetadata(),
        enabled: parent.isEnabled()
    });
}

// tslint:disable-next-line:max-classes-per-file
class NodeRecord extends Record(defaultNodeRecordProps) implements Node {
    public getId(): NodeId {
        return this.id;
    }

    public getName(): NodeName {
        return this.name;
    }

    public getTechnicalName(): NodeName {
        const nodeName = parseNodeMetadata(this).$nodeName || this.getName();

        return nodeName as NodeName;
    }

    public changeName(name: NodeName): NodeRecord {
        return this.set('name', name);
    }

    public getDescription(): NodeDescription {
        return this.description;
    }

    public getType(): NodeType {
        const type = parseNodeMetadata(this).$nodeType || this.type;

        return type as NodeType;
    }

    public getLink(): NodeLink | null {
        return this.link;
    }

    public getTags(): List<NodeTag> {
        return this.tags;
    }

    public isLayer(): boolean {
        return this.layer;
    }

    public isDefaultLayer(): boolean {
        return this.defaultLayer;
    }

    public getParent(): Node | null {
        return this.parent;
    }

    public changeParent(parent: Node | null): Node {
        return this.set('parent', parent);
    }

    public children(): List<Node> {
        return this.childrenList;
    }

    public getSources(): List<Node> {
        return this.sourcesList;
    }

    public getTargets(): List<Node> {
        return this.targetsList;
    }

    public getGeometry(): GraphPoint {
        return this.geometry;
    }

    public getSize(): Size {
        return this.size;
    }

    public getMetadata(): string | null {
        return this.metadata;
    }

    public setChildren(children: List<Node>): Node {
        return this.set('childrenList', children);
    }

    public changeMetadata(metadata: string): NodeRecord {
        return this.set('metadata', metadata);
    }

    public isEnabled(): boolean {
        return this.get('enabled');
    }

    public changeGeometry(geometry: GraphPoint): Node {
        return this.set('geometry', geometry);
    }

    public changeSize(size: Size): Node {
        return this.set('size', size);
    }
}
