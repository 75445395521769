import {JSONSchema7} from "json-schema";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {BoardId, BoardName, ServiceName} from "../../../model/Board";
import {makeBoardByIdSelector} from "../../../selectors/board";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {UiSchema} from "../../../service/cody-wizard/ui/ui-schema";
import ExpandButton from "./ExpandButton";
import {
  getItemProperties,
  getPossibleCommands,
  getPossibleListVOs,
  getPossiblePages
} from "./Typeahead/elements-tree-lookup";
import {makeCommandButtonUiSchema, makeTableUiSchema, makeUiSchema} from "./Validation/ui-schema";


interface OwnProps {
  boardId: BoardId;
  nodeSchema: Schema;
  schema: UiSchema;
  onSchemaChanged: (schema: UiSchema | undefined) => void;
  autofocus?: boolean;
  buttonSchema?: boolean;
  tableSchema?: boolean;
  readonly?: boolean;
  sessionId?: string;
}

type UiSchemaEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const UiSchemaEditor = (props: UiSchemaEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const board = useSelector(makeBoardByIdSelector(props.boardId));
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);

  let value = JSON.stringify(props.schema, null, 2) || '{\n  \n}\n';

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `ui-schema.json`,
      language: "json",
      value,
      schema: makeUiSchema({
        ...getPropertiesUiSchema(props.nodeSchema),
        ...(props.buttonSchema? makeCommandButtonUiSchema() : {}),
        ...(props.tableSchema? makeTableUiSchema(getItemProperties(props.nodeSchema, elementsTree), getPossiblePages(elementsTree), getPossibleListVOs(elementsTree)) : {}),
      }, getPossibleListVOs(elementsTree), getPossibleCommands(elementsTree)),
    });

    setInvalidJson(isInvalidJson);
  }, [value, props.nodeSchema.toString(), board.name]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onSchemaChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedSchema = JSON.parse(editorStr);

      props.onSchemaChanged(changedSchema);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] UI Schema Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.ui_schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorExpanded} onExpandChanged={expand => setEditorExpanded(expand)}/>
    <CodeEditor
      containerId={'cody-ui-schema-editor'}
      ref={codeEditorRef}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorExpanded ? ' expanded' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedSchemaStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedSchemaStr || "{}");

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedSchemaStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(UiSchemaEditor);

const getPropertiesUiSchema = (nodeSchema: Schema): Record<string, JSONSchema7> => {
  const props = nodeSchema.getObjectProperties();

  const propsSchema: Record<string, JSONSchema7> = {};

  props.forEach(prop => {

    const propSchema = nodeSchema.getObjectPropertySchema(prop, undefined);

    if(propSchema && propSchema.isObject()) {
      const propPropertiesUiSchema = getPropertiesUiSchema(propSchema);
      propsSchema[prop] = {
        oneOf: [
          {
            $ref: "#/definitions/ui_schema"
          },
          {
            type: "object",
            properties: {
              ...propPropertiesUiSchema
            }
          }
        ]
      }
    } else {
      propsSchema[prop] = {
        $ref: "#/definitions/ui_schema"
      }
    }
  })

  return propsSchema;
}


