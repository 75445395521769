import {List} from "immutable";
import {useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Icon, List as SemanticList, Ref, Sticky, Transition} from "semantic-ui-react";
import EditableTextline from "../../core/components/EditableTextline";
import {useGraph} from "../hooks/useGraph";
import {Action} from "../index";
import {BoardId, BoardName} from "../model/Board";
import {GraphPoint, Node, NodeId, NodeLink, NodeName, NodeTag, NodeType, Size} from "../model/Graph";
import {makeBoardByIdSelector, makeGetBoard} from "../selectors/board";
import {makeElementsTreeByBoardIdSelector} from "../selectors/elementsTree";
import BoardLayer from "./BoardTreeView/BoardLayer";
import BoardTree from "./BoardTreeView/BoardTree";
import {withNamespaces, WithNamespaces} from "react-i18next";

export const EDITABLE_LAYER_INPUT_CHAR_SIZE = 9;
export const EDITABLE_LAYER_CHAR_SIZE_UNIT = 'px';
export const EDITABLE_LAYER_ADDITIONAL_CHAR_PADDING = 0;

export const isValidLeaf = (ele: Node): boolean => {
    switch (ele.getType()) {
        case NodeType.misc:
        case NodeType.edge:
        case NodeType.textCard:
            return false;
        default:
            if(ele.getName() === '') {
                return false;
            }
            return true;
    }
};

interface OwnProps {

}

interface RouteMatchParams {
    uid: BoardId;
}

type BoardTreeViewProps = OwnProps & RouteComponentProps<RouteMatchParams> & WithNamespaces;


class NoContextNode implements Node {
    private childrenList: List<Node>;
    private boardName: string;

    constructor (children: List<Node>, boardName: BoardName) {
        this.childrenList = children;
        this.boardName = boardName;
    }

    public getId(): NodeId {
        return '__NO_CONTEXT__';
    }

    public getName(): NodeName {
        return this.boardName;
    }

    public getTechnicalName(): NodeName {
        return this.getName();
    }

    public changeName(name: NodeName): NoContextNode {
        // do nothing
        return this;
    }

    public getDescription(): NodeName {
        return '';
    }

    public getType(): NodeType {
        return NodeType.boundedContext;
    }

    public getLink(): NodeLink | null {
        return null;
    }

    public getTags(): List<NodeTag> {
        return List();
    }

    public isLayer(): boolean {
        return false;
    }

    public isDefaultLayer(): boolean {
        return false;
    }

    public getParent(): Node | null {
        return null;
    }

    public changeParent(parent: Node | null): Node {
        // Do nothing
        return this;
    }

    public children(): List<Node> {
        return this.childrenList;
    }

    public setChildren(children: List<Node>): Node {
        this.childrenList = children;
        return this;
    }

    public getSources(): List<Node> {
        return List();
    }

    public getTargets(): List<Node> {
        return List();
    }

    public getGeometry(): GraphPoint {
        return {x: 0, y: 0};
    }

    public getSize(): Size {
        return {width: 0, height: 0};
    }

    public getMetadata(): string | null {
        return null;
    }

    public changeMetadata(metadata: string): NoContextNode {
        // do nothing
        return this;
    }

    public isEnabled(): boolean {
        return true;
    }

    public changeGeometry(geometry: GraphPoint): Node {
        // do nothing
        return this;
    }

    public changeSize(size: Size): Node {
        // do nothing
        return this;
    }
}

const BoardTreeView = (props: BoardTreeViewProps) => {
    const boardId = props.match.params.uid;
    const getElementsTreeSelector = useMemo(() => makeElementsTreeByBoardIdSelector(boardId), []);
    const elementsTree = useSelector(getElementsTreeSelector);
    const dispatch = useDispatch();
    const [transitionVisible, setTransitionVisible] = useState(true);
    const board = useSelector(makeBoardByIdSelector(boardId));
    const [graph] = useGraph();
    const [visibilityChanges, setVisibilityChanges] = useState(0);
    const [activeLayerChanges, setActiveLayerChanges] = useState(0);
    const [showNewLayerInput, setShowNewLayerInput] = useState(false);
    const [copyIntoLayerPossible, setCopyIntoLayerPossible] = useState(false);
    const [showCopyToLayerSuccess, setShowCopyToLayerSuccess] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        graph.onSelectionChanged(hasSelection => setCopyIntoLayerPossible(hasSelection));
    }, []);

    const layers = elementsTree.getFilteredElementsAsTree().sortBy(l => l.getName());

    const noContextNodes: Node[] = [];

    let contexts = elementsTree.getFilteredElementsAsTree().filter(ele => {
        if(ele.getType() === NodeType.boundedContext) {
            return true;
        }

        if(isValidLeaf(ele)) {
            noContextNodes.push(ele);
        }

        return false;
    });

    if(noContextNodes.length > 0) {
        const others: List<Node> = List(noContextNodes);

        const noContextNode = new NoContextNode(others, board.name);

        contexts = contexts.push(noContextNode);
    }

    const handleApplyVisibilityGloballyClick = () => {
        graph.applyVisibilityGlobally();
        setVisibilityChanges(visibilityChanges + 1);
    }

    const handleAddLayerClick = () => {
        setShowNewLayerInput(true);
    }

    const handleCancelAddLayer = () => {
        setShowNewLayerInput(false);
    }

    const handleAddLayer = (layerName: string) => {
        graph.addLayer(layerName);

        setShowNewLayerInput(false);
    }

    const handleLayerVisibilityChanged = (layer: Node, visible: boolean): void => {
        if(!visible && graph.isActiveLayer(layer)) {
            let defaultLayerSetAsActive = false;

            layers.forEach(l => {
                if(l.isDefaultLayer() && graph.isVisible(l)) {
                    defaultLayerSetAsActive = true;
                    graph.setAsActiveLayer(l);
                }
            })

            if(!defaultLayerSetAsActive) {
                for (const l of layers.toArray()) {
                    if(graph.isVisible(l)) {
                        graph.setAsActiveLayer(l);
                    }
                }
            }
        }

        setVisibilityChanges(visibilityChanges + 1);
    }

    const handleCopyToLayerClick = () => {
        graph.copySelectionToActiveLayer();
        setShowCopyToLayerSuccess(true);

        window.setTimeout(() => {
            setShowCopyToLayerSuccess(undefined);
        }, 2000);
    }

    return <>
        <div className="item">
            <SemanticList className="elements tree">
                <SemanticList.Item style={{paddingBottom: '0px'}}>
                    <div className="context-menu">
                        <Button icon={true}
                                basic={true}
                                size="medium"
                                onClick={handleAddLayerClick}
                                disabled={showNewLayerInput}
                                title={props.t('insp.board.layers.add_layer')}
                        >
                            <Icon name="plus" />
                        </Button>
                        <Button icon={true}
                                basic={true}
                                size={"medium"}
                                disabled={!copyIntoLayerPossible}
                                onClick={handleCopyToLayerClick}
                                title={props.t('insp.board.layers.insert_selection')}
                        >
                            <Icon name="window restore outline" />
                        </Button>
                        {/*User visibility is deactivated. For more info see MxGraphWrapper::setUserVisible */}
                        {/*<Transition visible={visibilityChanges % 2 === 0} animation="pulse" duration={1000}>
                            <Button icon={true}
                                    basic={true}
                                    size="medium"
                                    floated="right"
                                    disabled={!graph.hasUserLevelVisibilityChanges()}
                                    onClick={handleApplyVisibilityGloballyClick}
                                    title={props.t('insp.board.layers.set_global_visibility')}
                            >
                                <Icon name="share square"/>
                            </Button>
                        </Transition>*/}
                    </div>
                </SemanticList.Item>
                {showNewLayerInput && <SemanticList.Item className="add-layer">
                    <Button icon={true} basic={true} size="medium">
                        <Icon name="folder outline" />
                    </Button>
                    <EditableTextline
                        text={''}
                        charSize={EDITABLE_LAYER_INPUT_CHAR_SIZE}
                        widthUnit={EDITABLE_LAYER_CHAR_SIZE_UNIT}
                        padding={EDITABLE_LAYER_ADDITIONAL_CHAR_PADDING}
                        minWidth={120}
                        required={true}
                        onTextChanged={handleAddLayer}
                        focus={true}
                        onCancel={handleCancelAddLayer}
                        placeholder={'Layer name'}
                    />
                    </SemanticList.Item>
                }
                {
                    layers.map(layer => <BoardLayer
                        key={layer.getId()}
                        name={layer.getName() === '' ? 'Board' : layer.getName()}
                        boardId={boardId}
                        layer={layer}
                        deletable={!layer.isDefaultLayer()}
                        active={graph.isActiveLayer(layer)}
                        onLayerVisibilityChanged={(visible: boolean) => handleLayerVisibilityChanged(layer, visible)}
                        onActiveLayerChanged={() => setActiveLayerChanges(activeLayerChanges + 1)}
                        copyToLayerSuccess={showCopyToLayerSuccess}
                        expand={(elementsTree.isFiltered() && layer.children().count() > 0) || layer.isDefaultLayer()}>
                        {layer.children().count() > 0
                            ? <BoardTree leafs={layer.children()} boardId={boardId} expandLeafs={elementsTree.isFiltered()} />
                            : <SemanticList><SemanticList.Item><span style={{color: '#747474'}}>(empty)</span></SemanticList.Item></SemanticList>
                        }
                    </BoardLayer>)
                }
            </SemanticList>
        </div>
    </>
};

export default withNamespaces()(withRouter(BoardTreeView));
