import {List} from "immutable";
import {cloneDeep} from "lodash";
import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, ButtonGroup} from "semantic-ui-react";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {ElementsTree} from "../../../model/ElementsTree";
import {
  Graph,
  makeNodeFromJs,
  NextToPosition,
  Node,
  NodeType
} from "../../../model/Graph";
import {getCommandMetadata} from "../../../service/cody-wizard/command/get-command-metadata";
import {
  ImmutableDDDWizardContext,
  isDDDAction
} from "../../../service/cody-wizard/context/ddd-action";
import {ImmutableUiContext, uiContextFromNode} from "../../../service/cody-wizard/context/ui";
import {updateContext} from "../../../service/cody-wizard/context/wizard-context";
import {fqcn} from "../../../service/cody-wizard/node/fqcn";
import {getSourcesOfType, getTargetsOfType} from "../../../service/cody-wizard/node/get-single-node-of-type";
import {markAsConnected} from "../../../service/cody-wizard/node/mark-as-connected";
import {names} from "../../../service/cody-wizard/node/names";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {
  ensurePluralNoun,
  ensureSingularNoun,
  isAdjective,
  isNoun,
  tagSentence
} from "../../../service/cody-wizard/tagger";
import {getUiMetadata, UiMetadata} from "../../../service/cody-wizard/ui/get-ui-metadata";
import {getSchemaRefName} from "../../../service/cody-wizard/vo/get-schema-ref-name";
import {getVoMetadata, PageLinkTableColumn, ValueObjectMetadata} from "../../../service/cody-wizard/vo/get-vo-metadata";
import {isListVo} from "../../../service/cody-wizard/vo/is-list-vo";
import {isStateVo} from "../../../service/cody-wizard/vo/is-state-vo";
import EditorDivider from "../Editor/EditorDivider";
import PageContentEditor from "../Editor/PageContentEditor";
import CodySuggestButton from "../Editor/Suggestion/CodySuggestButton";
import UiConfigEditor from "../Editor/UiConfigEditor";
import {DEFAULT_CARD_HEIGHT, DEFAULT_CONNECT_MARGIN, SLICE_LANE_MARGIN, WithWizardStepProps} from "../WizardModal";
import UiAccessibleFromSelect, {AccessibleFrom} from "./Ui/UiAccessibleFromSelect";

interface OwnProps {

}

type UiProps = OwnProps & WithWizardStepProps<ImmutableDDDWizardContext | ImmutableUiContext> & WithNamespaces;

const EmptyUiContext = {ui: null, uiViews: List([]), parentUi: null};

const Ui = (props: UiProps) => {
  const ui = props.ctx.ui;

  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedUi, setSelectedUi] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<UiMetadata>({commands: [], views: []});
  const [autoFocusUiConfig, setAutoFocusUiConfig] = useState(false);
  const [topLevel, setTopLevel] = useState<boolean>(true);
  const [parentUi, setParentUi] = useState<Node | null>(null);
  const [viewModels, setViewModels] = useState<List<Node>>(List());

  const updateMetadata = (meta: UiMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(JSON.stringify(meta, null, 2), true);
    }

    setMetadata(meta);
  }

  const updateParentUi = (changedParentUi: Node | null, newMeta: UiMetadata ) => {
    if(parentUi && !changedParentUi) {
      const clonedMeta = {...newMeta};
      delete clonedMeta.parent;
      updateMetadata(clonedMeta);
    } else if (changedParentUi) {
      updateMetadata({...newMeta, parent: changedParentUi.getId()});

      if(isDDDAction(props.ctx) && selectedUi && props.ctx.state) {
        linkToPageFromParentUIIfPossible(selectedUi, changedParentUi, props.ctx.state, graph, elementsTree);
      }
    }

    setParentUi(changedParentUi);
    props.onCtxChanged(updateContext(props.ctx, {parentUi: changedParentUi}));
  }

  useEffect(() => {
    resetForm();

    setViewModels(props.ctx.uiViews);

    if(ui) {
      setSelectedUi(ui);
      let meta = getUiMetadata(ui);
      let shouldUpdateMetadata = false;

      const initialParentUi = props.ctx.parentUi || (meta.parent && graph.getNode(meta.parent)) || null;

      const isTopLevel = suggestTopLevel(initialParentUi, props.ctx);
      setTopLevel(isTopLevel);

      if(!meta.route) {
        meta = suggestPageConfig(meta, ui, props.ctx.parentUi, isTopLevel, props.ctx.uiViews, props.ctx);
        shouldUpdateMetadata = true;
      }

      setMetadata(meta);

      if(initialParentUi) {
        setParentUi(initialParentUi);
      }

      if(shouldUpdateMetadata) {
        updateMetadata(meta);
      }
    }
  }, [ui])

  const resetForm = () => {
    setSelectedUi(null);
    setMetadata({commands: [], views: []});
    setAutoFocusUiConfig(false);
    setTopLevel(true);
    setParentUi(null);
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleAccessibleFromChanged = (changedAccessibleFrom: AccessibleFrom) => {
    if(!changedAccessibleFrom) {
      setTopLevel(false);
      updateParentUi(null, suggestPageConfig(metadata, selectedUi!, null, false, viewModels, props.ctx));
      return;
    }

    if(typeof changedAccessibleFrom === "string") {
      setTopLevel(true);
      updateParentUi(null, suggestPageConfig(metadata, selectedUi!, null, true, viewModels, props.ctx));
      return;
    }

    setTopLevel(false);
    updateParentUi(changedAccessibleFrom, suggestPageConfig(metadata, selectedUi!, changedAccessibleFrom, false, viewModels, props.ctx));
  }

  const handlePageConfigChanged = (meta: UiMetadata) => {
    updateMetadata(meta);
  }

  const handleSuggestConfig = () => {
    if(!selectedUi) {
      return;
    }

    const newPageConfig = suggestPageConfig(metadata, selectedUi, parentUi, topLevel, viewModels, props.ctx, false, true);

    if(JSON.stringify(newPageConfig) !== JSON.stringify(metadata)) {
      handlePageConfigChanged(newPageConfig);
    }
  }

  const handleSuggestContent = () => {
    if(!selectedUi) {
      return;
    }

    const newPageConfig = suggestPageConfig(metadata, selectedUi, parentUi, topLevel, viewModels, props.ctx, true, false);

    if(JSON.stringify(newPageConfig) !== JSON.stringify(metadata)) {
      handlePageConfigChanged(newPageConfig);
    }
  }

  const accessibleFrom = parentUi || (topLevel ? 'sidebar' : undefined);

  return <form className='ui form' onSubmit={handleSubmit}>
    <UiAccessibleFromSelect graph={graph} boardId={props.ctx.board.uid}
                            disabled={!!(selectedUi && !selectedUi.isEnabled())} ctx={props.ctx}
                            accessibleFrom={accessibleFrom} ui={selectedUi}
                            onAccessibleFromChanged={handleAccessibleFromChanged}/>
    <EditorDivider content={props.t('insp.cody_wizard.step_ui.page_config_label') as string}/>
    <UiConfigEditor boardId={props.ctx.board.uid}
                    sessionId={selectedUi ? selectedUi.getId() : undefined}
                    uiConfig={metadata}
                    readonly={!!(selectedUi && !selectedUi.isEnabled())}
                    onUiConfigChanged={handlePageConfigChanged}
                    autofocus={autoFocusUiConfig}
                    ctx={props.ctx}
    />
    <ButtonGroup>
      <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestConfig}/>
      <Button basic={true} compact={true} icon="search" className="noborder"
              content={props.t('insp.cody_wizard.ui_schema_editor.choose_icon')} as="a"
              href="https://pictogrammers.com/library/mdi/" target="_blank"/>
    </ButtonGroup>
    <EditorDivider content={props.t('insp.cody_wizard.step_ui.page_content_label') as string}/>
    <PageContentEditor boardId={props.ctx.board.uid}
                       sessionId={selectedUi ? selectedUi.getId() : undefined}
                       uiConfig={metadata}
                       readonly={!!(selectedUi && !selectedUi.isEnabled())}
                       onUiConfigChanged={handlePageConfigChanged}
                       autofocus={autoFocusUiConfig}
                       ctx={props.ctx}
    />
    <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestContent}/>
    <div style={{marginTop: '100px'}}/>
  </form>

};

export default withNamespaces()(Ui);

export const updateViewModelsOnGraph = (ui: Node, parentUi: Node | null, ctx: ImmutableDDDWizardContext | ImmutableUiContext, viewModels: List<Node>, graph: Graph): boolean => {
  const existingViewModelIds = ctx.uiViews.map(vm => vm.getId());
  const changedViewModelIds = viewModels.map(vm => vm.getId());

  const viewModelsToDelete = ctx.uiViews.filter(vm => !changedViewModelIds.contains(vm.getId()));
  const viewModelsToAdd = viewModels.filter(vm => !existingViewModelIds.contains(vm.getId()));

  viewModelsToDelete.forEach(vm => graph.deleteNode(vm));

  const lastExistingViewModel = ctx.uiViews.last(undefined);

  let anchorNode = lastExistingViewModel || parentUi;
  let position = NextToPosition.above;
  let margin: number | {x: number, y: number} = DEFAULT_CONNECT_MARGIN;

  if(graph.isEventModelingEnabled()) {
    anchorNode = lastExistingViewModel || null;
    position = NextToPosition.left;

    if(!anchorNode) {
      anchorNode = ui;
      position = NextToPosition.belowLeft;
      const uiToApiMarign = ctx.sliceApiLabel
        ? ctx.sliceApiLabel.getGeometry().y - ui.getGeometry().y - DEFAULT_CARD_HEIGHT
        : SLICE_LANE_MARGIN;
      margin = {x: DEFAULT_CONNECT_MARGIN, y: uiToApiMarign}
    }
  } else {
    if(!anchorNode) {
      anchorNode = ui;
      position = NextToPosition.left;
    }
  }



  viewModelsToAdd.forEach(vm => {
    const newViewModel = makeNodeFromJs({
      id: graph.createCellId(),
      type: vm.getType(),
      name: vm.getTechnicalName(),
      metadata: vm.getMetadata(),
    });

    graph.addNodeNextToAnother(newViewModel, anchorNode as Node, position, margin);
    graph.connectNodes(newViewModel, ui, undefined, undefined, true);
    markAsConnected(newViewModel, graph);
    anchorNode = graph.getNode(newViewModel.getId());
    position = NextToPosition.above;
  })

  return viewModelsToAdd.count() > 0 || viewModelsToDelete.count() > 0;
}

export const suggestPageConfig = (currentPageConfig: UiMetadata, ui: Node, parentUi: Node | null, topLevel: boolean, viewModels: List<Node>, ctx: ImmutableDDDWizardContext | ImmutableUiContext, onlyContent = false, onlyConfig = false): UiMetadata => {
  const meta = cloneDeep(currentPageConfig);
  const suggestedRouteParams = suggestRouteParams(viewModels);

  if(!onlyContent) {
    meta.route = suggestRoute(ui, parentUi, topLevel, suggestedRouteParams, ctx);

    if(topLevel && (!meta.sidebar || !meta.sidebar.label )) {
      meta.sidebar = {
        label: ui.getName(),
        icon: 'square'
      }
    }

    if(!topLevel && meta.sidebar) {
      delete meta.sidebar;
    }

    if(!meta.breadcrumb) {
      meta.breadcrumb = ui.getName()
    }
  }

  if(!onlyConfig) {

    const commands = getTargetsOfType(ui, NodeType.command, true);

    commands.forEach(c => {
      const cName = fqcn(c);

      if(!meta.commands.includes(cName)) {
        meta.commands.push(cName);
      }
    })

    const views = getSourcesOfType(ui, NodeType.document, true);

    views.forEach(v => {
      const vName = fqcn(v, false, true);

      if(!meta.views.map(vc => typeof vc === "string" ? vc : vc.view).includes(vName)) {
        meta.views.push(vName);
      }
    })

  }

  return meta;
}

export const suggestUiRoutePart = (ui: Node | null, topLevel: boolean, routeParams: string[], ctx: ImmutableDDDWizardContext | ImmutableUiContext): string => {
  if(!ui) {
    return  "/";
  }

  if(topLevel) {
    return `/${names(ui.getTechnicalName()).fileName}`
  }

  if(routeParams.length) {
    return "/" + routeParams.map(param => `:${param}`).join("/");
  }

  return "/";
}

export const suggestTopLevel = (parentUi: Node | null, ctx: ImmutableDDDWizardContext | ImmutableUiContext): boolean => {
  if(parentUi) {
    return false;
  } else if(isDDDAction(ctx) && ctx.command) {
    const cmdMeta = getCommandMetadata(ctx.command);

    if(cmdMeta.aggregateCommand && typeof cmdMeta.newAggregate !== "undefined") {
      return cmdMeta.newAggregate;
    }
  }

  return true;
}

export const suggestUiName = (ctx: ImmutableDDDWizardContext | ImmutableUiContext, graph: Graph, topLevel: boolean): string | undefined => {
  let name = '';

  const suffix = topLevel ? '' : ' Details';

  if(isDDDAction(ctx)) {
    if(ctx.state) {
      name = ctx.state.getTechnicalName();
    } else if(ctx.aggregate) {
      name = ctx.aggregate.getTechnicalName();
    } else if(ctx.command) {
      name = ctx.command.getTechnicalName();
    } else if(ctx.events.count()) {
      name = ctx.events.first(makeNodeFromJs({})).getTechnicalName();
    }

    if(name === '') {
      return suffix;
    }

    const nodeNames = names(name);

    const words = tagSentence(nodeNames.title);

    console.log("[CodyWizard] tagged words: ", words);

    return words.filter(word => isNoun(word) || isAdjective(word))
      .map(word =>  isNoun(word) ? topLevel ? ensurePluralNoun(word) : ensureSingularNoun(word) : word.token).join(" ") + `${suffix}`;
  } else {
    const firstViewModel = ctx.uiViews.first(undefined);

    if(firstViewModel) {
      return firstViewModel.getTechnicalName() + `${suffix}`;
    }

    if(ctx.parentUi) {
      const parentUiWords = tagSentence(ctx.parentUi.getTechnicalName());
      return parentUiWords.map(word => isNoun(word)? ensureSingularNoun(word) : word.token).join(" ") + `${suffix}`;
    }
  }
}

export const suggestRouteParams = (viewModels: List<Node>): string[] => {
  const routeParams: string[] = [];

  viewModels.forEach(m => {
    const voMeta = getVoMetadata(m);

    if(voMeta.schema && voMeta.schema.isObject() && voMeta.identifier) {
      routeParams.push(voMeta.identifier);
    }
  })

  return routeParams;
}

export const suggestRoute = (ui: Node | null, parentUI: Node | null, topLevel: boolean, suggestedRouteParams: string[], ctx: ImmutableDDDWizardContext | ImmutableUiContext): string => {
  let route = '';

  if(parentUI) {
    const parentUIMeta = getUiMetadata(parentUI);

    if(parentUIMeta.route) {
      route +=  parentUIMeta.route;
    } else {
      route += '/' + names(parentUI.getTechnicalName()).fileName;
    }
  }
  if(ui) {
    route += suggestUiRoutePart(ui, topLevel, suggestedRouteParams, ctx);
  }

  if(route === "") {
    route = suggestUiRoutePart(ui, topLevel, suggestedRouteParams, ctx);
  }

  return route;
}

export const suggestViewModels = (topLevel: boolean, ctx: ImmutableDDDWizardContext | ImmutableUiContext): List<Node> => {
  if(ctx.uiViews.count() > 0) {
    return ctx.uiViews;
  }

  if(!topLevel && isDDDAction(ctx) && ctx.state) {
    return List.of(ctx.state);
  }

  return List();
}

export const linkToPageFromParentUIIfPossible = (page: Node, parentUi: Node, state: Node, graph: Graph, elementsTree: ElementsTree): Node | undefined => {
  const stateMeta = getVoMetadata(state);
  const stateRef = getSchemaRefName(state, stateMeta);
  const pageName = names(page.getTechnicalName()).className;

  const result = findStateListViewModel(stateRef, parentUi, elementsTree);

  if(!result) {
    return;
  }

  const [stateListVO, stateListVOMeta] = result;

  const uiSchema = stateListVOMeta.uiSchema || {};

  if(!uiSchema.table || !uiSchema.table.columns) {
    return;
  }

  const columns = uiSchema.table.columns;
  let hasPageLink = false;
  let voMetaChanged = false;

  columns.forEach(c => {
    if(c.pageLink && getPageNameFromPageLink(c.pageLink) === pageName) {
      hasPageLink = true;
    }
  })

  const firstColumn = columns[0];

  if(!hasPageLink && firstColumn && !firstColumn.pageLink) {
    firstColumn.pageLink = pageName;
    voMetaChanged = true;
  }

  if(voMetaChanged) {
    uiSchema.table.columns = columns;

    graph.changeNodeMetadata(stateListVO, JSON.stringify({...stateListVOMeta, uiSchema}, null, 2));

    return stateListVO;
  }
}

const getPageNameFromPageLink = (pageLink: string | PageLinkTableColumn): string => {
  if(typeof pageLink === "string") {
    return pageLink;
  }

  return pageLink.page;
}

const findStateListViewModel = (stateRef: string, parentUi: Node, elementsTree: ElementsTree): [Node, ValueObjectMetadata] | null => {
  const allCopiesOfParentUi = elementsTree.getFilteredElementsByTypeAndLabel(NodeType.ui, parentUi.getName());

  const viewModels: Node[] = [];

  allCopiesOfParentUi.forEach(copy => {
    viewModels.push(...getSourcesOfType(copy, NodeType.document))
  });

  let stateListViewModel: Node | null = null;
  let stateListViewModelMeta: ValueObjectMetadata | null = null;

  viewModels.forEach(vm => {
    if(isListVo(vm)) {
      const listVoMeta = getVoMetadata(vm);
      const itemsSchema = listVoMeta.schema!.getListItemsSchema(new Schema({}));

      if(itemsSchema.isRef() && itemsSchema.getRef() === stateRef) {
        stateListViewModel = vm;
        stateListViewModelMeta = listVoMeta;
      }
    }
  })

  if(stateListViewModel && stateListViewModelMeta) {
    return [stateListViewModel, stateListViewModelMeta];
  }

  return null;
}
