import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {BoardId} from "../../../model/Board";
import {RulesDependency, RulesDependencyMetadata} from "../../../service/cody-wizard/rule-engine/types";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import ExpandButton from "./ExpandButton";
import {makeDependenciesSchema} from "./Validation/dependencies-schema";

interface OwnProps {
  boardId: BoardId;
  dependencies: Record<string, RulesDependencyMetadata>;
  messageSchema: Schema;
  onDependenciesChanged: (dependencies: Record<string, RulesDependencyMetadata> | undefined) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
}

type DependenciesEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const DependenciesEditor = (props: DependenciesEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);

  let value = props.dependencies ? dependenciesToString(props.dependencies) : '';

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `dependencies-schema.json`,
      language: "json",
      value,
      schema: props.dependencies ? makeDependenciesSchema(props.dependencies, props.messageSchema, elementsTree) : undefined,
    });

    setInvalidJson(isInvalidJson);
  }, [props.sessionId, value]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onDependenciesChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedDependenciesConfig = JSON.parse(editorStr);
      props.onDependenciesChanged(changedDependenciesConfig);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] Dependencies Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.query_schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorExpanded} onExpandChanged={expand => setEditorExpanded(expand)}/>
    <CodeEditor
      containerId={'cody-dependencies-editor'}
      ref={codeEditorRef}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorExpanded ? ' expanded' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedDependenciesStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedDependenciesStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedDependenciesStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(DependenciesEditor);

type DependencyConfig = Record<string, {alias?: string, options?: Record<string, any>}>;

const dependenciesToString = (dependencies: Record<string, RulesDependencyMetadata>): string => {
  return JSON.stringify(dependencies, null, 2);
}
