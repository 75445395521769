import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, ButtonGroup} from "semantic-ui-react";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {ElementsTree} from "../../../model/ElementsTree";
import {makeNodeFromJs, Node, NodeType} from "../../../model/Graph";
import {CommandMetadata, getCommandMetadata} from "../../../service/cody-wizard/command/get-command-metadata";
import {ImmutableDDDWizardContext} from "../../../service/cody-wizard/context/ddd-action";
import {suggestDependenciesForNode} from "../../../service/cody-wizard/dependencies/suggest-dependencies-for-node";
import {getEventMetadata} from "../../../service/cody-wizard/event/get-event-metadata";
import {names} from "../../../service/cody-wizard/node/names";
import {Rule} from "../../../service/cody-wizard/rule-engine/configuration";
import {RulesDependencyMetadata} from "../../../service/cody-wizard/rule-engine/types";
import {
  getSchemaFromNodeDescription
} from "../../../service/cody-wizard/schema/get-schema-from-node-description";
import { Schema } from "../../../service/cody-wizard/schema/schema";
import {
  ensurePresentTense,
  isVerb,
  tagSentence
} from "../../../service/cody-wizard/tagger";
import {UiSchema} from "../../../service/cody-wizard/ui/ui-schema";
import {getVoMetadata} from "../../../service/cody-wizard/vo/get-vo-metadata";
import {isStateVo} from "../../../service/cody-wizard/vo/is-state-vo";
import BusinessRulesEditor from "../Editor/BusinessRulesEditor";
import DependenciesEditor from "../Editor/DependenciesEditor";
import EditorDivider from "../Editor/EditorDivider";
import SchemaEditor from "../Editor/SchemaEditor";
import CodySuggestButton from "../Editor/Suggestion/CodySuggestButton";
import UiSchemaEditor from "../Editor/UiSchemaEditor";
import {WithWizardStepProps} from "../WizardModal";
import AdvancedButton from "./AdvancedButton";
import {suggestEventRecordingRules} from "./Aggregate";
import AggregateCommandToggle from "./AggregateCommand/AggregateCommandToggle";
import DeleteHistoryToggle from "./AggregateCommand/DeleteHistoryToggle";
import DeleteStateToggle from "./AggregateCommand/DeleteStateToggle";
import NewAggregateToggle from "./AggregateCommand/NewAggregateToggle";
import {suggestUiSchema} from "./AnyVo";
import StreamLockingToggle from "./AggregateCommand/StreamLockingToggle";
import StreamIdEditor from "../Editor/StreamIdEditor";
import {cloneDeep} from "lodash";

interface OwnProps {}

type DddCommandProps = OwnProps & WithWizardStepProps<ImmutableDDDWizardContext> & WithNamespaces;

const DddCommand = (props: DddCommandProps) => {
  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedCommand, setSelectedCommand] = useState<Node|null>(null);
  const [newCommand, setNewCommand] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<CommandMetadata>({aggregateCommand: false});
  const [autoFocusSchema, setAutoFocusSchema] = useState(false);
  const [showDependencies, setShowDependencies] = useState(false);
  const [dependencies, setDependencies] = useState<Record<string, RulesDependencyMetadata>>({});
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);
  const [showStreamIdEditor, setShowStreamIdEditor] = useState<boolean>(false);

  const updateMetadata = (meta: CommandMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(JSON.stringify(meta, null, 2), true);
    }

    setMetadata(meta);
  }

  useEffect(() => {
    resetForm();

    if(props.ctx.command) {
      setSelectedCommand(props.ctx.command);
      const commandMeta = getCommandMetadata(props.ctx.command);
      let shouldUpdateMetadata = false;

      if(props.ctx.commandDependencies) {
        commandMeta.dependencies = props.ctx.commandDependencies;
      }

      if(!commandMeta.uiSchema) {
        const suggestedUiSchema = suggestUiSchema(commandMeta.schema || new Schema({}), undefined, elementsTree, props.ctx, true);

        if(suggestedUiSchema) {
          shouldUpdateMetadata = true;
          commandMeta.uiSchema = suggestedUiSchema;
        }
      }

      if (!commandMeta.aggregateCommand && props.ctx.state && isStateVo(props.ctx.state)) {
        commandMeta.aggregateCommand = true;
        commandMeta.newAggregate = suggestCommandCreatesNewAggregate(props.ctx, elementsTree, true);
        shouldUpdateMetadata = true;
      }

      if((!commandMeta.rules || commandMeta.rules && commandMeta.rules.length === 0) && !props.ctx.aggregate && props.ctx.events.count() > 0) {
        const newRules = suggestEventRecordingRules(props.ctx);
        commandMeta.rules = newRules;
        shouldUpdateMetadata = true;
        props.onCtxChanged({...props.ctx, commandHandler: newRules});
      }

      if(shouldUpdateMetadata) {
        updateMetadata(commandMeta);
      }

      if(commandMeta.streamId) {
        setShowStreamIdEditor(true);
      }

      setMetadata(commandMeta);
    }

    if(Object.keys(props.ctx.commandDependencies).length) {
      setShowDependencies(true);
    }

  }, [props.ctx.command])

  useEffect(() => {
    setDependencies(props.ctx.commandDependencies || {});
  }, [props.ctx.commandDependencies]);

  const resetForm = () => {
    setSelectedCommand(null);
    setNewCommand(null);
    setMetadata({aggregateCommand: false});
    setAutoFocusSchema(false);
    setDependencies({});
    setShowDependencies(false);
    setShowStreamIdEditor(false);
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleNewAggregateChanged = (newAggregate: boolean) => {
    updateMetadata({...metadata, newAggregate});
  }

  const handleDeleteStateChanged = (deleteState: boolean) => {
    updateMetadata({...metadata, deleteState, deleteHistory: false});
  }

  const handleDeleteHistoryChanged = (deleteHistory: boolean) => {
    updateMetadata({...metadata, deleteHistory});
  }

  const handleSchemaChanged = (newSchema: Schema | undefined) => {
    if(!newSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.schema;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, schema: newSchema});
  }

  const handleUiSchemaChanged = (newUiSchema: UiSchema | undefined) => {
    if(!newUiSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.uiSchema;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, uiSchema: newUiSchema});
  }

  const handleSuggestSchema = () => {
    if(props.ctx.state) {
      const stateMeta = getVoMetadata(props.ctx.state);

      if(stateMeta.schema && !stateMeta.schema.isEmpty()) {
        handleSchemaChanged(stateMeta.schema);
        return;
      }
    }

    const firstEvent = props.ctx.events.first(undefined);

    if(firstEvent) {
      const eventMeta = getEventMetadata(firstEvent);

      if(eventMeta.schema && !eventMeta.schema.isEmpty()) {
        handleSchemaChanged(eventMeta.schema);
        return;
      }
    }

    if(props.ctx.uiViews.first(undefined)) {
      const firstView = props.ctx.uiViews.first() as Node;

      const viewMeta = getVoMetadata(firstView);

      if(viewMeta.schema && (viewMeta.schema.isObject() || viewMeta.schema.isRef())) {
        handleSchemaChanged(viewMeta.schema);
        return;
      }
    }

    const descSchema = props.ctx.command? getSchemaFromNodeDescription(props.ctx.command) : new Schema({});

    if(!descSchema.isEmpty()) {
      handleSchemaChanged(descSchema);
      return;
    }

    handleSchemaChanged(new Schema({}));
  }

  const handleSuggestUiSchema = () => {
    if(props.ctx.state) {
      const stateMeta = getVoMetadata(props.ctx.state);

      if(stateMeta.uiSchema) {
        const uiSchema = {
          ...(metadata.uiSchema && metadata.uiSchema["ui:button"]? {"ui:button": metadata.uiSchema["ui:button"]} : {}),
          ...stateMeta.uiSchema
        };
        handleUiSchemaChanged(uiSchema as UiSchema);
        return;
      }
    }

    const suggestedUiSchema = suggestUiSchema(metadata.schema || new Schema({}), metadata.uiSchema, elementsTree, props.ctx, true);

    if(suggestedUiSchema) {
      handleUiSchemaChanged(suggestedUiSchema);
    }
  }

  const updateDependencies = (deps: Record<string, RulesDependencyMetadata>) => {
    props.onCtxChanged({...props.ctx, commandDependencies: deps});
    setDependencies(deps);
    updateMetadata({...metadata, dependencies: deps});
  }

  const handleDependenciesConfigChanged = (changedDependencies: Record<string, RulesDependencyMetadata> | undefined) => {
    updateDependencies(changedDependencies || {});
  }

  const handleSuggestDependencies = () => {
    if(!props.ctx.command) {
      return;
    }

    const newDeps = suggestDependenciesForNode(props.ctx.command, dependencies);

    if(JSON.stringify(newDeps) !== JSON.stringify(dependencies)) {
      handleDependenciesConfigChanged(newDeps);
    }
  }

  const handleRulesChanged = (newRuleSet: Rule[] | undefined) => {
    if(!newRuleSet) {
      newRuleSet = [];
    }

    props.onCtxChanged({...props.ctx, commandHandler: newRuleSet});
    updateMetadata({...metadata, rules: newRuleSet});
  }

  const handleAggregateCommandChanged = (aggregateCommand: boolean) => {
    updateMetadata({...metadata, aggregateCommand});
  }

  const handleStreamLockingChanged = (streamLocking: boolean) => {
    setShowStreamIdEditor(streamLocking);
    if(!streamLocking) {
      handleStreamIdChanged('');
    }
  }

  const handleStreamIdChanged = (streamId: string) => {
    if(!streamId) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.streamId;
      updateMetadata(clonedMeta);
    } else {
      updateMetadata({...metadata, streamId});
    }
  }

  return <form className='ui form' onSubmit={handleSubmit}>
    {metadata.aggregateCommand && <NewAggregateToggle command={props.ctx.command || newCommand} disabled={!!(selectedCommand && !selectedCommand.isEnabled())} ctx={props.ctx} newAggregate={metadata.newAggregate} deleteState={metadata.deleteState} onNewAggregateChanged={handleNewAggregateChanged} />}
    {metadata.aggregateCommand && <DeleteStateToggle command={props.ctx.command || newCommand} disabled={!!(selectedCommand && !selectedCommand.isEnabled())} deleteState={metadata.deleteState} newAggregate={metadata.newAggregate} onDeleteStateChanged={handleDeleteStateChanged} />}
    {metadata.aggregateCommand && <DeleteHistoryToggle command={props.ctx.command || newCommand} disabled={!!(selectedCommand && !selectedCommand.isEnabled())} deleteHistory={metadata.deleteHistory} deleteState={metadata.deleteState} onDeleteHistoryChanged={handleDeleteHistoryChanged} />}
    <EditorDivider content="Schema" />
    <SchemaEditor boardId={props.ctx.board.uid}
                  sessionId={props.ctx.command ? props.ctx.command.getId() : undefined}
                  schema={metadata.schema || Schema.fromString('{}')}
                  readonly={!!(selectedCommand && !selectedCommand.isEnabled())}
                  onSchemaChanged={handleSchemaChanged} autofocus={autoFocusSchema}
    />
    <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestSchema} />
    <EditorDivider content="UI Schema" />
    <UiSchemaEditor boardId={props.ctx.board.uid}
                    sessionId={props.ctx.command ? props.ctx.command.getId() : undefined}
                    nodeSchema={metadata.schema || Schema.fromString('{}')}
                    schema={metadata.uiSchema || {}}
                    readonly={!!(selectedCommand && !selectedCommand.isEnabled())}
                    onSchemaChanged={handleUiSchemaChanged} buttonSchema={true}
    />
    <ButtonGroup>
      <CodySuggestButton hasSuggestions={true} onSuggest={handleSuggestUiSchema} />
      <Button basic={true} compact={true} icon="search" className="noborder" content={props.t('insp.cody_wizard.ui_schema_editor.choose_icon')} as="a" href="https://pictogrammers.com/library/mdi/" target="_blank" />
    </ButtonGroup>
    {!props.ctx.aggregate && <>
      <div className={'field'}>
        <EditorDivider content={'Command Handler'} />
        {<Button basic={true} icon={showDependencies ? 'minus' : 'add'} labelPosition="left" size="mini"
                 content={props.t('insp.cody_wizard.step_aggregate.manage_dependencies') + ` (${Object.keys(dependencies).length})`}
                 color="blue" className="text"
                 onClick={() => setShowDependencies(!showDependencies)}
                 disabled={!selectedCommand || !selectedCommand.isEnabled()}/>}
      </div>
      <div className={(showDependencies ? 'slidedown' : 'slideup') + ' smooth'}>
        <DependenciesEditor boardId={props.ctx.board.uid}
                            dependencies={dependencies}
                            messageSchema={metadata.schema || Schema.fromString('{}')}
                            autofocus={false}
                            onDependenciesChanged={handleDependenciesConfigChanged}
                            readonly={!selectedCommand || !selectedCommand.isEnabled()}
                            sessionId={props.ctx.command ? props.ctx.command.getId() : undefined}
        />
        <CodySuggestButton hasSuggestions={!!(props.ctx.command && props.ctx.command.getSources().filter(s => [NodeType.document, NodeType.externalSystem, NodeType.event].includes(s.getType())).count() > 0)}
                           onSuggest={handleSuggestDependencies}
                           />
      </div>
      <EditorDivider content={props.t('insp.cody_wizard.step_aggregate.rules_label') as string}/>
      <BusinessRulesEditor rules={metadata.rules || []}
                           elementId={props.ctx.command ? props.ctx.command.getId() : ''}
                           sessionId={props.ctx.command ? props.ctx.command.getId() : undefined}
                           dependencies={dependencies}
                           onRulesChanged={handleRulesChanged}
                           autofocus={false}
                           possibleEvents={props.ctx.events}
                           readonly={!selectedCommand || !selectedCommand.isEnabled()}
      />
    </>}
    <AdvancedButton showAdvancedSettings={showAdvancedSettings} onShowChanged={show => setShowAdvancedSettings(show)}  disabled={!selectedCommand} />
    <div className={(showAdvancedSettings? 'slidedown' : 'slideup') + ' smooth'}>
      <EditorDivider content={'Classification'} />
      <AggregateCommandToggle command={selectedCommand} aggregateCommand={metadata.aggregateCommand} ctx={props.ctx} onAggregateCommandChanged={handleAggregateCommandChanged} />
      <StreamLockingToggle  command={selectedCommand} aggregateCommand={metadata.aggregateCommand} streamLocking={showStreamIdEditor} ctx={props.ctx} onStreamLockingChanged={handleStreamLockingChanged} />
      {showStreamIdEditor && <StreamIdEditor ctx={props.ctx}
                                             streamId={metadata.streamId || ''}
                                             sessionId={props.ctx.command ? props.ctx.command.getId() : undefined}
                                             autofocus={showStreamIdEditor}
                                             readonly={!selectedCommand || !selectedCommand.isEnabled()}
                                             boardId={props.ctx.board.uid}
                                             onStreamIdChanged={handleStreamIdChanged}
      />}
    </div>
    <div style={{marginTop: '100px'}}/>
  </form>
};

export default withNamespaces()(DddCommand);

export const suggestAggregateCommandSchema = (ctx: ImmutableDDDWizardContext, newAggregate: boolean): Schema => {
  const firstEvent = ctx.events.first(undefined);
  const state = ctx.state;
  if (newAggregate && state) {
    const stateMeta = getVoMetadata(state);

    if (stateMeta.schema) {
      return stateMeta.schema;
    }
  }

  if (firstEvent) {
    const evtMeta = getEventMetadata(firstEvent);

    if (evtMeta.schema) {
      return evtMeta.schema;
    }
  }

  if (state) {
    const stateMetaAgain = getVoMetadata(state);

    if (stateMetaAgain.identifier) {
      const rawSchema: Record<string, any> = {};
      rawSchema[stateMetaAgain.identifier] = 'string|format:uuid';
      return new Schema(rawSchema)
    }
  }

  return new Schema({});
}

export const suggestAggregateCommandUiSchema = (ctx: ImmutableDDDWizardContext): UiSchema => {
  if (ctx.state) {
    const stateMeta = getVoMetadata(ctx.state);

    if (stateMeta.uiSchema) {
      return stateMeta.uiSchema;
    }

    if (stateMeta.identifier) {
      const uiSchema: Record<string, any> = {};
      uiSchema[stateMeta.identifier] = {
        'ui:widget': 'hidden'
      }

      return uiSchema;
    }
  }

  return {};
}

export const suggestNewCommandName = (ctx: ImmutableDDDWizardContext): string | undefined => {
  const name = '';

  if(ctx.events.first(undefined)) {
    const eventName = ctx.events.first(makeNodeFromJs({})).getTechnicalName();
    const eventNameTitle = names(eventName).title;

    const words = tagSentence(eventNameTitle);

    console.log("[CodyWizard] tagged words: ", words);

    const rest = words.filter(w => !isVerb(w)).map(w => w.token);
    const verbs = words.filter(w => isVerb(w)).map(w => ensurePresentTense(w));

    return verbs.join(" ") + " " + rest.join(" ");
  }

  return name;

  // @TODO check if new ar or update command, suggest name based on state/ar name + Add or Update
}

export const suggestCommandCreatesNewAggregate = (ctx: ImmutableDDDWizardContext, elementsTree: ElementsTree, isAggregateCommand?: boolean): boolean => {
  if(ctx.aggregate) {
    const similarAggregates = elementsTree.getFilteredElementsByTypeAndLabel(NodeType.aggregate, ctx.aggregate.getTechnicalName())
      .filter(ele => ele.getId() !== ctx.aggregate!.getId());

    return similarAggregates.count() === 0;
  }

  if(ctx.state) {
    const similarStates = elementsTree.getFilteredElementsByTypeAndLabel(NodeType.document, ctx.state.getTechnicalName())
      .filter(ele => ele.getId() !== ctx.state!.getId());

    return similarStates.count() === 0;
  }

  return !!isAggregateCommand;
}
