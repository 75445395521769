import {AxiosInstance} from "axios";
import {CodyResponse, CodyResponseType} from "../Cody";
import {CodyServer} from "./CodyServer";

export class AxiosServer implements CodyServer {
  public readonly name: string;
  private readonly axios: AxiosInstance;
  private sync: boolean = false;

  public constructor(name: string, axios: AxiosInstance) {
    this.name = name;
    this.axios = axios;
  }

  public async sendMessage(messageName: string, payload: any): Promise<CodyResponse> {
    const res = await this.axios.post<CodyResponse>(messageName, payload, {validateStatus: () => true});

    if(res.status !== 200) {
      return {
        cody: `Did not receive a valid response from ${this.name}.`,
        type: CodyResponseType.Error,
        details: JSON.stringify(res)
      }
    }

    return res.data;
  }

  public async fullSync(messageName: string, payload: any): Promise<CodyResponse> {
    return this.sendMessage(messageName, payload);
  }

  public async syncNodes(messageName: string, payload: any): Promise<CodyResponse> {
    const res = await this.axios.put<CodyResponse>(messageName, payload);

    if(res.status !== 200) {
      return {
        cody: `Did not receive a valid response from ${this.name}.`,
        type: CodyResponseType.Error,
        details: JSON.stringify(res)
      }
    }

    return res.data;
  }

  public async syncDeletedNodes(messageName: string, payload: any): Promise<CodyResponse> {
    const res = await this.axios.post<CodyResponse>(messageName, payload);

    if(res.status !== 200) {
      return {
        cody: `Did not receive a valid response from ${this.name}.`,
        type: CodyResponseType.Error,
        details: JSON.stringify(res)
      }
    }

    return res.data;
  }

  public enableSync(): void {
    this.sync = true;
  }

  public isSyncEnabled(): boolean {
    return this.sync;
  }
}
