import {List, Record} from 'immutable';
import {detectService} from "../service/cody-wizard/node/detect-service";
import {fqcn} from "../service/cody-wizard/node/fqcn";
import {names} from "../service/cody-wizard/node/names";
import {Graph, Node, NodeId, NodeType} from "./Graph";

interface ParsedFilter {
    nodeIds: string[];
    nodeTypes: string[];
    nodeLabels: string[];
    nodeTags: string[];
    notNodeTags: string[];
    strict: boolean;
}

export interface TreeProps {
    elements: List<Node>;
    filter: string;
    focus: boolean;
}

export const defaultTreeProps: TreeProps = {
    elements: List(),
    filter: '',
    focus: false,
};

export const createTreeFromGraph = (graph: Graph): ElementsTree => {
    return new ElementsTree({elements: graph.getRoot().children()});
};

export const parseFilter = (filter: string): ParsedFilter => {
    const parts = filter.split(';');

    const nodeIds: string[] = [];
    const nodeTypes: string[] = [];
    const nodeLabels: string[] = [];
    const nodeTags: string[] = [];
    const notNodeTags: string[] = [];

    parts.forEach(part => {
        if(part.search('id:') !== -1) {
            nodeIds.push(part.replace('id:', '').trim());
        }else if(part.search('type:') !== -1) {
            nodeTypes.push(part.replace('type:', '').trim().toLowerCase());
        } else if(part.search('!tag:') !== -1) {
            notNodeTags.push(part.replace('!tag:', '').trim().toLowerCase());
        } else if(part.trim().search(/^!#/) !== -1) {
            notNodeTags.push(part.replace('!#', '').trim().toLowerCase());
        } else if(part.search('tag:') !== -1) {
            nodeTags.push(part.replace('tag:', '').trim().toLowerCase());
        } else if(part.trim().search(/^#/) !== -1) {
            nodeTags.push(part.replace('#', '').trim().toLowerCase());
        } else {
            nodeLabels.push(part.trim());
        }
    })

    return {nodeIds, nodeTypes, nodeLabels, nodeTags, notNodeTags, strict: false};
}

export const isFeatureTypeFilter = (filter: ParsedFilter): boolean => {
    return filter.nodeTypes.length === 1 && filter.nodeTypes[0] === NodeType.feature;
}

export const isContextTypeFilter = (filter: ParsedFilter): boolean => {
    return filter.nodeTypes.length === 1 && filter.nodeTypes[0] === NodeType.boundedContext.toLowerCase();
}

const matchNode = (node: Node, filter: ParsedFilter): boolean => {
    if(node.getTags().contains(ispConst.TAG_ELEMENTS_TREE_HIDDEN)) {
        return false;
    }

    if(filter.nodeTypes.length && !filter.nodeTypes.includes(node.getType().toLowerCase())) {
        return false;
    }

    if(filter.nodeIds.length && !filter.nodeIds.includes(node.getId())) {
        return false;
    }

    if(filter.nodeTags.length) {
        for (const tag of filter.nodeTags) {
            if(!node.getTags().contains(tag)) {
                // @TODO: Remove fallback after a while, fixed spelling 2019-11-26
                if(tag === 'planned') {
                    if(!node.getTags().contains('planed')) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        }
    }

    if(filter.notNodeTags.length) {
        for (const tag of filter.notNodeTags) {
            if(node.getTags().contains(tag)) {
                return false;
            }
        }
    }

    let matchesAllLabelParts = true;

    filter.nodeLabels.forEach(labelFilter => {
        if(filter.strict) {
            if(node.getName() !== labelFilter) {
                matchesAllLabelParts = false;
            }
        } else {
            if(node.getName().toLowerCase().search(labelFilter.toLowerCase()) === -1) {
                matchesAllLabelParts = false;
            }
        }
    });

    return matchesAllLabelParts;
};

const filterChildren = (children: List<Node>, filter: ParsedFilter): List<Node> => {
    const filteredChildren: Node[] = [];
    const isFeatureFilter = isFeatureTypeFilter(filter);
    const isContextFilter = isContextTypeFilter(filter);

    children.forEach(child => {
        if(isFeatureFilter && child.getType() === NodeType.feature) {
            if(matchNode(child, filter)) {
                filteredChildren.push(child);
            }

            return;
        }

        if(isContextFilter && child.getType() === NodeType.boundedContext) {
            if(matchNode(child, filter)) {
                filteredChildren.push(child);
            }
            return;
        }

        const filteredSubChildren = filterChildren(child.children(), filter);

        if(filteredSubChildren.count() > 0) {
            filteredChildren.push(child.setChildren(filteredSubChildren));
            return;
        }

        if(matchNode(child, filter)) {
            filteredChildren.push(child.setChildren(filteredSubChildren))
        }
    });

    return List(filteredChildren);
};

const filterElements = (elements: List<Node>, filter: ParsedFilter): List<Node> => {
    const filteredElements: Node[] = [];

    elements.forEach(ele => {

       const filteredSubChildren = filterElements(ele.children(), filter);

       if(filteredSubChildren.count() > 0) {
           filteredElements.push(...filteredSubChildren.toArray());
       }

       if(matchNode(ele, filter)) {
           filteredElements.push(ele);
       }
    });

    return List(filteredElements);
}

export class ElementsTree extends Record(defaultTreeProps) implements TreeProps {
    public mergeTree(other: ElementsTree): ElementsTree {
        // @TODO Implement diffing strategy to only add/remove/update changed elements
        return other.set('filter', this.filter);
    }

    public getFilteredElementsAsTree(): List<Node> {
        const parsedFilter = parseFilter(this.filter);

        return filterChildren(this.elements, parsedFilter);
    }

    public isFiltered(): boolean {
        return this.filter !== '';
    }

    public getSimilarElements(node: Node): List<Node> {
        const nodeName = this.getNamespacedName(node);
        return this.getFilteredElementsByTypeAndLabel(node.getType(), node.getName()).filter(se => {
            return se.getId() !== node.getId()
                && this.getNamespacedName(se) === nodeName
        });
    }

    public getFilteredElementsByTypeAndLabel(nodeType: string, label: string): List<Node> {
        return filterElements(this.elements, {
            nodeIds: [],
            nodeLabels: [label],
            strict: true,
            nodeTags: [],
            notNodeTags: [],
            nodeTypes: [nodeType.toLowerCase()]
        })
    }

    public getFilteredElementsByType(nodeType: string, uniqueLabels?: boolean): List<Node> {
        const filteredElements = filterElements(this.elements, {
            nodeIds: [],
            nodeLabels: [],
            strict: true,
            nodeTags: [],
            notNodeTags: [],
            nodeTypes: [nodeType.toLowerCase()]
        }).sortBy(ele => ele.getName());

        if(!uniqueLabels) {
            return filteredElements;
        }

        const namesList: string[] = [];

        return filteredElements.filter(ele => {
            if(namesList.includes(ele.getName())) {
                return false;
            }

            namesList.push(ele.getName());

            return true;
        })
    }

    public getFilteredElementsByTypes(nodeTypes: NodeType[], uniqueLabels?: boolean): List<Node> {
        const filteredElements = filterElements(this.elements, {
            nodeIds: [],
            nodeLabels: [],
            strict: true,
            nodeTags: [],
            notNodeTags: [],
            nodeTypes: nodeTypes.map(f => f.toLowerCase())
        }).sortBy(ele => ele.getName());

        if(!uniqueLabels) {
            return filteredElements;
        }

        const namesList: string[] = [];

        return filteredElements.filter(ele => {
            if(namesList.includes(ele.getName())) {
                return false;
            }

            namesList.push(ele.getName());

            return true;
        })
    }

    public getElement(elementId: NodeId): Node | undefined {
        const matchedEles = filterElements(this.elements, {
            nodeIds: [elementId],
            nodeLabels: [],
            nodeTags: [],
            notNodeTags: [],
            nodeTypes: [],
            strict: true
        });

        if(matchedEles.size > 0) {
            return matchedEles.first();
        }

        return undefined;
    }

    private getNamespacedName(node: Node): string {
        if(node.getType() === NodeType.event) {
            let service = detectService(node);
            if(service) {
                service += '.';
            } else {
                service = '';
            }
            // FQCN of event might include aggregate name, but for similar nodes that's not what we want to compare
            return `${service}` + names(node.getName()).className;
        }

        return fqcn(node, false, false, true);
    }

}
