import {Node, NodeName, NodeType} from "../../../model/Graph";
import {parseNodeMetadata} from "../node/metadata";
import {Rule} from "../rule-engine/configuration";

export interface DynamicBreadcrumbMetadata {
  data: string;
  label: Rule[] | string;
}

export interface DynamicSidebarMetadata {
  data: string;
  label?: string;
  icon?: string;
  hidden?: string;
}

export type ViewComponent = string | { view: string, hidden: boolean}

export interface UiMetadata {
  $nodeName?: NodeName;
  $nodeType?: NodeType;
  route?: string;
  parent?: string;
  routeParams?: string[];
  sidebar?: {label?: string ; icon?: string; hidden?: boolean; "hidden:expr"?: string; dynamic?: DynamicSidebarMetadata};
  breadcrumb?: string | DynamicBreadcrumbMetadata;
  tab?: {label?: string, group?: string, icon?: string};
  commands: string[];
  views: ViewComponent[];
}

export const getUiMetadata = (ui: Node): UiMetadata => {
  const uiMeta = parseNodeMetadata(ui);

  if(!uiMeta.commands) {
    uiMeta.commands = [];
  }

  if(!uiMeta.views) {
    uiMeta.views = [];
  }

  return uiMeta as UiMetadata;
}
